<template>
	<div class="relative translate-y-1/2 sm:translate-y-1/3">
		<div class="mx-auto w-1/2 sm:w-1/4">
			<img src="../../../public/lembraZapLogo.png" class="select-none dark:hidden" alt="Logo LembraZap" />
		</div>
		<form @submit.prevent="submeteFormulario" class="flex flex-col items-center mx-5 md:mx-0">
			<CampoDeTexto v-model="login" tipo="text" class="w-full sm:w-1/4 mb-3" holder="Login" :obrigatorio="true" />
			<CampoDeTexto v-model="senha" tipo="password" class="w-full sm:w-1/4 select-none" holder="Senha" :obrigatorio="true" autocomplete="on" />
			<button
				type="submit"
				class="mt-10 px-2 py-3 sm:py-5 w-1/2 sm:w-1/4 bg-zinc-900 p-2 font-medium text-white text-sm rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-black hover:text-opacity-50"
			>
				Entrar
			</button>
		</form>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount} from "vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import {toast} from "@/hooks/toast";
	import {useStore} from "vuex";
	import {useRouter} from "vue-router";
	import {realiza_login, realiza_logout} from "@/store/typeActions.js";

	const store = useStore();
	const router = useRouter();
	const login = ref("");
	const senha = ref("");

	const logar = async usuario => await store.dispatch(realiza_login, usuario);
	const submeteFormulario = async () => {
		const {success, rota} = await logar({email: login.value, password: senha.value});
		if (!success) return toast("Usuário ou senha inválidos!", "error");

		router.push({path: rota});
		toast("Login efetuado com sucesso!", "success");
	};

	onBeforeMount(async () => await store.dispatch(realiza_logout));
</script>
