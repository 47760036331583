<template>
	<div class="flex flex-col items-center justify-center h-screen bg-background-pf">
		<div class="relative text-center">
			<div class="relative z-10 titulo__vicent">
				<h1 class="text-3xl md:text-5xl font-bold text-yellow-pf">Vicent... Vicent... eu estou no interfone.</h1>
				<p class="text-lg md:text-xl font-semibold text-orange-pf">Opa, você parece estar perdido, {{ texto }}</p>
			</div>
			<img src="../../public/Vicent.png" class="select-none absolute vicent z-0" alt="Imagem de fundo do Pulp Fiction" />
			<div class="flex flex-col items-center botoes__vicent">
				<button v-if="autorizado" @click="router.push('/admin/numeros')" class="font-semibold relative z-10 bg-purple-pf text-white text-lg px-5 py-3 my-5 rounded-sm">
					Ir para os números
				</button>
				<button
					@click="() => router.back()"
					class="font-semibold relative z-10 text-orange-pf border-b border-b-orange-pf"
					:class="autorizado ? 'text-base' : 'text-lg mt-10 sm:mt-16'"
				>
					Retornar
				</button>
			</div>
		</div>
	</div>
</template>
<script setup>
	import {isAuthenticated} from "@/services/auth";
	import {onBeforeMount, onBeforeUnmount, ref} from "vue";
	import {useRouter} from "vue-router";

	const router = useRouter();
	const autorizado = ref(false);
	const texto = ref("");

	onBeforeMount(() => {
		autorizado.value = isAuthenticated();
		texto.value = autorizado.value ? "que tal ir para a tela de números?" : "acho melhor retornar para o login!";
		document.title = "404 - não encontrado";
	});

	onBeforeUnmount(() => (document.title = "BestMessage - Dashboard"));
</script>
