<template>
	<div class="my-2 mx-4 md:my-5 md:mx-">
		<button
			class="my-5 p-2 rounded-full mr-4 flex items-center dark:hover:bg-zinc-600 dark:active:bg-zinc-700 hover:bg-gray-200 active:bg-gray-400 active:rounded-full"
			@click="() => router.back()"
		>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
			</svg>
			<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
		</button>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base overflow-auto">
				<thead class="select-none cursor-pointer">
					<tr class="text-center sm:text-left text-header">
						<th class="px-2 py-3 dark:text-zinc-200">PC</th>
						<th class="px-2 py-3 dark:text-zinc-200">QTD Emuladores</th>
						<th class="px-2 py-3 dark:text-zinc-200">Alternador</th>
						<th class="px-2 py-3 dark:text-zinc-200">Último update alternador</th>
						<th class="px-2 py-3 dark:text-zinc-200">QTD Envios / Total</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-center sm:text-left text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default" v-for="(emulador, index) in emuladores" :key="index">
						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700">{{ emulador.pc }}</td>
						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700">{{ emulador.qtd }}</td>
						<td class="mx-1 px-2 py-3 font-normal" :class="statusEmulador(emulador.ultimoUpdateAlternador) === 'Offline' ? 'text-red-700' : 'text-green-700'">
							{{ statusEmulador(emulador.ultimoUpdateAlternador) }}
						</td>
						<td class="mx-1 px-2 py-3 font-normal dark:text-zinc-200 text-gray-700">{{ formataData(emulador.ultimoUpdateAlternador, "DD/MM/YYYY - HH:mm", "") }}</td>
						<td class="mx-1 px-2 py-3 font-normal dark:text-zinc-200 text-gray-700">
							<b class="text-green-600">{{ emulador.qtdEnviouRobo }}</b> / <b>{{ emulador.qtd }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount} from "vue";
	import api from "@/services/api.js";
	import moment from "moment";
	import {useRouter} from "vue-router";
	import {formataData} from "@/hooks/formatDate";

	const emuladores = ref([]);
	const router = useRouter();

	const fetchData = async () => {
		const response = await api.post("v1/emuladores/list");
		emuladores.value = response.data.data;
	};

	const statusEmulador = data => {
		if (!data) return "Offline";

		const diff = moment().diff(data, "hours");
		if (diff > 2) return "Offline";

		return "Online";
	};

	onBeforeMount(async () => await fetchData());
</script>
