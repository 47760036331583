<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Editar informações de acesso" />
		<button
			class="mb-5 col-span-12 sm:col-span-4 md:col-span-2 2xl:col-span-1 p-2 rounded-full mr-4 hover:bg-gray-200 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 flex items-center active:bg-gray-400 active:rounded-full"
			@click="router.back()"
		>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
			</svg>
			<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
		</button>
		<form @submit.prevent="salvar">
			<div class="grid grid-cols-12 gap-4">
				<div class="col-span-12 md:col-span-3">
					<label class="block text-header font-semibold dark:text-zinc-200">Nome</label>
					<CampoDeTexto v-model="nome" tipo="text" class="w-full h-8 mt-1" :obrigatorio="true" holder="Edite o nome" />
				</div>
				<div class="col-span-12 md:col-span-3">
					<label class="block text-header font-semibold dark:text-zinc-200">Senha</label>
					<CampoDeTexto v-model="senha" tipo="text" class="w-full h-8 mt-1" holder="Deixe vazio para manter a senha" />
				</div>
				<div class="col-span-12 md:col-span-3">
					<label class="block text-header font-semibold dark:text-zinc-200">Email</label>
					<CampoDeTexto v-model="email" tipo="text" class="w-full h-8 mt-1" :obrigatorio="true" holder="Edite o email" />
				</div>
				<div class="col-span-12 md:col-span-3" v-if="clientePersonalizado">
					<label class="block text-header font-semibold dark:text-zinc-200">Fila Própria</label>
					<CampoDeTexto v-model="nomeFilaPropria" tipo="text" class="w-full h-8 mt-1" :obrigatorio="false" holder="Fila do RabbitMQ" />
				</div>
			</div>
			<div class="grid grid-cols-12 gap-4 my-3">
				<div class="col-span-12 md:col-span-3">
					<label class="block text-header font-semibold dark:text-zinc-200">Quantidade maturação</label>
					<CampoDeTexto v-model="qtd_fake" tipo="number" class="w-full h-8 mt-1" holder="Quantidade" />
				</div>
				<div class="col-span-12 md:col-span-3">
					<label class="block text-header font-semibold dark:text-zinc-200">Intervalo</label>
					<CampoDeTexto v-model="intervalo" tipo="number" class="w-full h-8 mt-1" holder="Intervalo" />
				</div>
				<div class="col-span-12 md:col-span-3">
					<label class="block text-header font-semibold dark:text-zinc-200">Encaminhas respostas para</label>
					<CampoDeTexto v-model="encaminhar_respostas" tipo="number" class="w-full h-8 mt-1" holder="Respostas" />
				</div>
			</div>
			<hr />
			<div class="grid grid-cols-12 gap-y-1 gap-x-3 my-3">
				<div class="col-span-12 md:col-span-6">
					<label class="text-lg text-header dark:text-white font-bold my-2">LZV2</label>
					<div class="grid grid-cols-12 gap-y-1 gap-x-3 my-3">
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="whatsappApiOficial" @input="() => (whatsappApiOficial = !whatsappApiOficial)" class="mr-2" />
								Usa Whatsapp Api Oficial
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="upload" @input="() => (upload = !upload)" class="mr-2" />
								Ativar Uploads
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="respostas" @input="() => (respostas = !respostas)" class="mr-2" />
								Ativar Respostas
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="validacao" @input="() => (validacao = !validacao)" class="mr-2" />
								Ativar Validação
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="centro_custo" @input="() => (centro_custo = !centro_custo)" class="mr-2" />
								Ativar Centro de Custo
								<label class="text-sm"> (Opção de criação de centro de custo, e vincular campanhas, operadores e chats a ele) </label>
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="contrato" @input="() => (contrato = !contrato)" class="mr-2" />
								Ativar Contrato
								<label class="text-sm"> (Validação do chat de um novo chat, passa a ser número e contrato, não apenas número) </label>
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="liberaEnvioAnexo" @input="() => (liberaEnvioAnexo = !liberaEnvioAnexo)" class="mr-2" />
								Liberar Envio Anexo
								<label class="text-sm"> (Envio com anexo para números blacklist) </label>
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="cliente_trestto" @input="() => (cliente_trestto = !cliente_trestto)" class="mr-2" />
								Cliente Trestto
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="cliente_novavida" @input="() => (cliente_novavida = !cliente_novavida)" class="mr-2" />
								Cliente Nova Vida TI
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="contaBlacklist" @input="() => (contaBlacklist = !contaBlacklist)" class="mr-2" />
								Números Blacklist
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="bloquearEnvios" @input="() => (bloquearEnvios = !bloquearEnvios)" class="mr-2" />
								Bloquear envios
							</label>
						</div>
						<div class="col-span-12">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium">
								<input type="checkbox" v-model="ativarPrePago" @input="() => (ativarPrePago = !ativarPrePago)" class="mr-2" />
								Ativar saldo pré-pago
							</label>
						</div>
						<div class="col-span-12 my-2" v-if="ativarPrePago">
							<label class="block text-lg text-header dark:text-zinc-200 font-medium"> Saldo pré-pago </label>
							<CampoDeTexto v-model="saldoEnviosPrePago" class="" tipo="number" holder="Configure o saldo" />
						</div>
						<div class="col-span-12 my-5">
							<div class="col-span-12">
								<label class="text-lg text-header dark:text-white font-bold my-2">TIPO CONTA</label>
							</div>
							<div class="col-span-12">
								<label class="block text-lg text-header dark:text-zinc-200 font-medium">
									<input type="checkbox" v-model="contaValidacao" @input="() => (contaValidacao = !contaValidacao)" class="mr-2" />
									Conta Validação
								</label>
							</div>
							<div class="col-span-12">
								<label class="block text-lg text-header dark:text-zinc-200 font-medium">
									<input type="checkbox" v-model="contaMassa" @input="() => (contaMassa = !contaMassa)" class="mr-2" />
									Conta Massa
								</label>
							</div>
							<div class="col-span-12">
								<label class="block text-lg text-header dark:text-zinc-200 font-medium">
									<input type="checkbox" v-model="contaCriacao" @input="() => (contaCriacao = !contaCriacao)" class="mr-2" />
									Conta Criação
								</label>
							</div>
							<div class="col-span-12">
								<label class="block text-lg text-header dark:text-zinc-200 font-medium">
									<input type="checkbox" v-model="contaRoot" @input="() => (contaRoot = !contaRoot)" class="mr-2" />
									Conta Root
								</label>
							</div>
							<div class="col-span-12">
								<label class="block text-lg text-header dark:text-zinc-200 font-medium">
									<input type="checkbox" v-model="clientePersonalizado" @input="() => (clientePersonalizado = !clientePersonalizado)" class="mr-2" />
									Cliente Personalizado
								</label>
							</div>
							<div class="col-span-12">
								<label class="block text-lg text-header dark:text-zinc-200 font-medium">
									<input type="checkbox" v-model="monitoramento" @input="() => (monitoramento = !monitoramento)" class="mr-2" />
									Monitoramento
								</label>
							</div>
							<div class="col-span-12">
								<label class="block text-lg text-header dark:text-zinc-200 font-medium">
									<input type="checkbox" v-model="financeiro" @input="() => (financeiro = !financeiro)" class="mr-2" />
									Financeiro
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-span-12 md:col-span-4 2xl:col-span-3">
					<label class="text-lg text-header dark:text-white font-bold my-2">Configuração de porcentagem</label>
					<div class="block my-2">
						<label class="block text-header font-semibold dark:text-zinc-200">Porcentagem massivo</label>
						<select
							v-model="porcentagemMassivoOficial"
							class="w-full text-sm font-lato font-medium border rounded-md box-border py-2 px-2 dark:bg-zinc-800 dark:focus:border-zinc-900 dark:focus:ring-1 dark:focus:ring-zinc-900 dark:hover:border-zinc-500 dark:hover:ring-1 dark:hover:ring-zinc-600 dark:border-zinc-700 dark:text-zinc-400 dark:placeholder:text-zinc-500 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 hover:border-blue-300 hover:ring-1 hover:ring-blue-900"
						>
							<option v-for="value in porcentagemList" :value="value">{{ value }}%</option>
						</select>
					</div>
				</div>
				<div class="col-span-12 my-5">
					<div class="col-span-12">
						<label class="text-lg text-header dark:text-white font-bold my-2">OPÇÕES DE LISTAGEM</label>
					</div>
					<div class="col-span-12 md:col-span-2">
						<label class="block text-lg text-header dark:text-zinc-200 font-medium">
							<input type="checkbox" v-model="listarNaTelaDeBanidos" @input="() => (listarNaTelaDeBanidos = !listarNaTelaDeBanidos)" class="mr-2" />
							Listar conta na tela de banidos
						</label>
					</div>
				</div>
				<div class="col-span-12">
					<label class="text-lg text-header dark:text-white font-bold my-2">FATURAMENTO</label>
				</div>
				<div class="col-span-12">
					<label class="block text-lg text-header dark:text-zinc-200 font-medium">
						<input type="checkbox" v-model="faturar" @input="() => (faturar = !faturar)" class="mr-2" />
						Faturar cliente
					</label>
				</div>
				<div class="col-span-12">
					<label class="block text-lg text-header dark:text-zinc-200 font-medium">
						<input type="checkbox" v-model="faturamento_visivel" @input="() => (faturamento_visivel = !faturamento_visivel)" class="mr-2" />
						Faturamento visível
					</label>
				</div>
				<div class="col-span-12 md:col-span-2">
					<label class="block text-lg text-header dark:text-zinc-200 font-medium">Faturamento Envio</label>
					<CampoDeTexto step="0.001" v-model="envio" class="w-full h-8 mt-1" tipo="number" holder="Envio" />
				</div>
				<div class="col-span-12 md:col-span-2">
					<label class="block text-lg text-header dark:text-zinc-200 font-medium">Faturamento Chat</label>
					<CampoDeTexto step="0.001" v-model="chat" class="w-full h-8 mt-1" tipo="number" holder="Chat" />
				</div>
				<div class="col-span-12 md:col-span-2">
					<label class="block text-lg text-header dark:text-zinc-200 font-medium">Faturamento Operador</label>
					<CampoDeTexto step="0.001" v-model="operador" class="w-full h-8 mt-1" tipo="number" holder="Operador" />
				</div>
			</div>
			<hr />
			<div class="grid grid-cols-12 gap-y-1 gap-x-3 my-3">
				<div class="col-span-12">
					<label class="block text-lg text-header dark:text-zinc-200 font-medium">
						<input type="checkbox" v-model="useUrlCallback" @input="() => (useUrlCallback = !useUrlCallback)" class="mr-2" />
						Ativar Callbacks
					</label>
				</div>
				<div class="col-span-12 md:col-span-3">
					<label class="block text-sm dark:text-zinc-200 text-header font-medium">URL Callback</label>
					<CampoDeTexto v-model="urlCallBack" class="w-full h-8 mt-1" tipo="text" holder="Callback" />
				</div>
				<div class="col-span-12 md:col-span-3">
					<label class="block text-sm dark:text-zinc-200 text-header font-medium ml-2">URL Callback Resposta</label>
					<CampoDeTexto v-model="urlCallBackResposta" class="w-full h-8 mt-1" tipo="text" holder="Resposta" />
				</div>
				<div class="col-span-12 md:col-span-3">
					<label class="block text-sm dark:text-zinc-200 text-header font-medium ml-2">URL Callback Encerrar Conversa</label>
					<CampoDeTexto v-model="urlCallbackEncerrarConversa" class="w-full h-8 mt-1" tipo="text" holder="Resposta" />
				</div>
			</div>
			<hr />
			<button
				type="button"
				@click="zeraContaDoUsuario()"
				class="bg-red-600 my-5 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-red-800 rounded-md block transition duration-300 ease-in-out hover:shadow-md hover:bg-red-800 hover:text-opacity-50"
			>
				Zerar conta
			</button>
			<button
				type="submit"
				class="bg-green-600 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md block transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
			>
				Salvar
			</button>
		</form>
	</div>
</template>
<script setup>
	import {onBeforeMount, ref} from "vue";
	import {useRoute, useRouter} from "vue-router";
	import {toast} from "@/hooks/toast";
	import api from "@/services/api.js";
	import Swal from "sweetalert2";
	import {useLoading} from "vue-loading-overlay";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";

	const nome = ref("");
	const email = ref("");
	const senha = ref("");
	const telefone = ref(null);
	const encaminhar_respostas = ref(null);
	const intervalo = ref(1);
	const qtd_fake = ref(0);
	const modulos = ref([]);
	const meusModulos = ref([]);
	const ativarPrePago = ref(false);
	const saldoEnviosPrePago = ref(false);
	const bloquearEnvios = ref(false);
	const envio = ref(0.0);
	const chat = ref(0.0);
	const operador = ref(0.0);
	const upload = ref(false);
	const validacao = ref(false);
	const respostas = ref(false);
	const centro_custo = ref(false);
	const contrato = ref(false);
	const liberaEnvioAnexo = ref(false);
	const useUrlCallback = ref(false);
	const faturamento_visivel = ref(false);
	const faturar = ref(false);
	const contaValidacao = ref(false);
	const contaMassa = ref(false);
	const contaBlacklist = ref(false);
	const contaRoot = ref(false);
	const cliente_trestto = ref(false);
	const whatsappApiOficial = ref(false);
	const porcentagemMassivoOficial = ref(100);
	const cliente_novavida = ref(false);
	const contaCriacao = ref(false);
	const monitoramento = ref(false);
	const financeiro = ref(false);
	const clientePersonalizado = ref(false);
	const listarNaTelaDeBanidos = ref(false);
	const nomeFilaPropria = ref("");
	const urlCallBack = ref("");
	const urlCallBackResposta = ref("");
	const urlCallbackEncerrarConversa = ref("");

	const porcentagemList = ref([0]);

	const $loading = useLoading({});
	const route = useRoute();
	const router = useRouter();

	const fetchData = async () => {
		const response = await api.get(`v1/users/${route.params.id}`);
		if (!response.data.success && response.data.err) return toast("Erro ao buscar usuário", "error");

		contaValidacao.value = response.data.contaValidacao;
		whatsappApiOficial.value = response.data.lzv2.whatsappApiOficial;

		contaBlacklist.value = response.data.contaBlacklist;
		contaRoot.value = response.data.root;
		monitoramento.value = response.data.monitoramento;
		financeiro.value = response.data.financeiro;
		contaCriacao.value = response.data.contaCriacao;
		contaMassa.value = response.data.contaMassa;
		porcentagemMassivoOficial.value = response.data.porcentagemMassivoOficial;
		nomeFilaPropria.value = response.data.lzv2.fila;
		upload.value = response.data.lzv2.upload;
		validacao.value = response.data.lzv2.validacao;
		respostas.value = response.data.lzv2.respostas;
		centro_custo.value = response.data.lzv2.centrocusto;
		cliente_trestto.value = response.data.lzv2.cliente_trestto;
		cliente_novavida.value = response.data.lzv2.cliente_novavida;
		contrato.value = response.data.lzv2.contato_contrato;
		liberaEnvioAnexo.value = response.data.lzv2.liberaEnvioAnexo;
		useUrlCallback.value = response.data.useUrlCallback;
		envio.value = response.data.lzv2.faturamento.envio;
		urlCallBack.value = response.data.urlCallback;
		urlCallBackResposta.value = response.data.urlCallbackRespostas;
		urlCallbackEncerrarConversa.value = response.data.urlCallbackEncerrarConversa;
		faturamento_visivel.value = response.data.lzv2.faturamento.visivel;
		faturar.value = response.data.lzv2.faturar;
		chat.value = response.data.lzv2.faturamento.chat;
		operador.value = response.data.lzv2.faturamento.operador;
		ativarPrePago.value = response.data.saldoPrePago.ativo;
		saldoEnviosPrePago.value = response.data.saldoPrePago.credito;
		bloquearEnvios.value = response.data.lzv2.bloquearEnvios;
		nome.value = response.data.nome;
		email.value = response.data.email;
		qtd_fake.value = response.data.qtdFake;
		intervalo.value = response.data.intervaloEntreMsgs;
		clientePersonalizado.value = response.data.clientePersonalizado;
		listarNaTelaDeBanidos.value = response.data.listarNaTelaDeBanidos;
		encaminhar_respostas.value = response.data.encaminharRespostas[0];

		const res = await api.post("v1/acl/list-modules", {usuario: route.params.id, all: true});
		if (!res.data.success) return toast("Erro ao importar módulos", "error");

		modulos.value = res.data.data;

		const responseMyModules = await api.post("v1/acl/list-acl-user", {usuario: route.params.id});
		if (!responseMyModules.data.success) return toast("Erro ao importar meus módulos", "error");

		meusModulos.value = responseMyModules.data.data;

		porcentagemList.value = [];
		for (let i = 100; i >= 0; i -= 5) {
			porcentagemList.value.push(i);
		}
	};

	const salvar = async () => {
		const dados = {
			_id: route.params.id,
			root: contaRoot.value,
			nome: nome.value,
			telefone: telefone.value,
			encaminharRespostas: encaminhar_respostas.value,
			email: email.value,
			monitoramento: monitoramento.value,
			financeiro: financeiro.value,
			useUrlCallback: useUrlCallback.value,
			urlCallback: urlCallBack.value,
			urlCallbackRespostas: urlCallBackResposta.value,
			urlCallbackEncerrarConversa: urlCallbackEncerrarConversa.value,
			qtdFake: qtd_fake.value,
			intervaloEntreMsgs: intervalo.value,
			Editroot: true,
			contaValidacao: contaValidacao.value,
			contaMassa: contaMassa.value,
			contaBlacklist: contaBlacklist.value,
			contaCriacao: contaCriacao.value,
			clientePersonalizado: clientePersonalizado.value,
			listarNaTelaDeBanidos: listarNaTelaDeBanidos.value,
			porcentagemMassivoOficial: porcentagemMassivoOficial.value,
			saldoPrePago: {
				ativo: ativarPrePago.value,
				credito: saldoEnviosPrePago.value,
			},
			lzv2: {
				upload: upload.value,
				whatsappApiOficial: whatsappApiOficial.value,
				validacao: validacao.value,
				respostas: respostas.value,
				centrocusto: centro_custo.value,
				contato_contrato: contrato.value,
				liberaEnvioAnexo: liberaEnvioAnexo.value,
				cliente_trestto: cliente_trestto.value,
				cliente_novavida: cliente_novavida.value,
				faturar: faturar.value,
				fila: nomeFilaPropria.value,
				bloquearEnvios: bloquearEnvios.value,
				faturamento: {
					envio: envio.value,
					chat: chat.value,
					operador: operador.value,
					visivel: faturamento_visivel.value,
				},
			},
		};
		if (senha.value !== "") dados.password = senha.value;

		const response = await api.put(`v1/users`, dados);
		if (!response.data.success) return toast(response.data.err, "error");

		toast(response.data.msg, "success");
		router.back();
	};

	const zeraContaDoUsuario = async () => {
		const confirmacao = await Swal.fire({
			title: "Zerar conta do cliente",
			text: `Deseja mesmo zerar a conta do cliente ${nome.value}?`,
			icon: "question",
			input: "checkbox",
			inputPlaceholder: "Deletar contas admin?",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.isConfirmed) {
			const deletarAdmin = confirmacao.value === 1;
			const _id = route.params.id;

			const loader = $loading.show({canCancel: false, isFullPage: true});

			const response = await api.post("v1/users/zerarContaCliente", {deletarAdmin, _id});

			loader.hide();

			if (!response.data.success) {
				return toast(response.data.err, "error");
			}

			await fetchData();
			toast("Sucesso ao resetar conta!", "success");
		}
	};

	onBeforeMount(async () => {
		await fetchData();
	});
</script>
