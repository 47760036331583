<template>
	<div>
		<img v-if="qrcode" :src="qrcode" />
		<h2 v-else>QRCode Não encontrado</h2>
	</div>
</template>
<script>
	import {defineComponent, inject, ref, onMounted, onUnmounted} from "vue";
	import {useRoute} from "vue-router";
	import api from "@/services/api.js";

	export default defineComponent({
		name: "AtualizarQR",
		setup() {
			const $socket = inject("$socket");
			const route = useRoute();
			const qrcode = ref(null);

			async function fetchData() {
				window.document.title = route.params.numero;
				const response = await api.get(`/getqrcode/${route.params.numero}`);
				if (response.data) qrcode.value = response.data.qr;
			}

			function qrSocket(qrSocket) {
				if (qrSocket && qrSocket.telefone && route.params.numero.toString() === qrSocket.telefone.toString()) {
					qrcode.value = qrSocket.qr;
				}
			}

			onUnmounted(() => $socket.off("qr"));
			onMounted(async () => {
				$socket.on("qr", qrSocket);
				await fetchData();
			});

			return {
				qrcode,
			};
		},
	});
</script>
