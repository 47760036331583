<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Banidos" />
		<div class="grid grid-cols-12 gap-6">
			<div class="col-span-12 md:col-span-7">
				<v-select
					class="md:w-1/3 bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					v-model="data.grafico.selecionado"
					:options="data.grafico.opcoes"
					label="email"
					placeholder="Selecione um gráfico"
				/>
				<VueApexCharts :options="grafico.options" height="400" :series="grafico.series" type="bar" />
			</div>
			<div class="col-span-12 md:col-span-5 md:mt-3">
				<div class="grid grid-cols-12 gap-4">
					<div class="col-span-12 md:col-span-6 2xl:col-span-4">
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2">
							Banidos total:
							<span class="text-base text-red-500"> {{ banidos.totalGeral }} </span>
						</p>
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2">
							Criados:
							<span class="text-base text-green-500"> {{ banidos.criados }} </span>
						</p>
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2">
							Criados para banidos:
							<span class="text-base" :class="banidos.criadosPorBanidos === 0 ? 'text-red-500' : 'text-green-500'">
								{{ banidos.criadosPorBanidos ? banidos.criadosPorBanidos : "0" }}
							</span>
							{{ banidos.criadosPorBanidos ? "/" : "" }} <span class="text-red-500" v-if="banidos.criadosPorBanidos">1</span>
						</p>
					</div>
					<div class="col-span-12 md:col-span-6 2xl:col-span-4">
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2">
							Banidos de 0 a 7 dias:
							<span class="text-base text-red-500"> {{ contas.banidos0a7 }} </span>
						</p>
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2">
							Banidos de 8 a 20 dias:
							<span class="text-base text-red-500"> {{ contas.banidos8a20 }} </span>
						</p>
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2">
							Banidos de 21 a 40 dias:
							<span class="text-base text-red-500"> {{ contas.banidos21a40 }} </span>
						</p>
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2">
							Banidos mais de 40 dias:
							<span class="text-base text-red-500"> {{ contas.banidosmaiores40 }} </span>
						</p>
					</div>
					<div class="col-span-12 md:col-span-6 2xl:col-span-4">
						<p class="text-gray-800 dark:text-zinc-200 font-medium mb-2" v-for="conta in contas.contasSelecionadas" :key="conta._id">
							{{ conta.nome }}:
							<span class="text-base text-red-500"> {{ conta.total }} </span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<form @submit.prevent="buscar" class="grid grid-cols-12 gap-4 my-4">
			<div class="col-span-12 md:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">Usuário</label>
				<v-select
					class="mt-1 w-full bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					v-model="data.usuario"
					:options="usuarios"
					label="email"
					placeholder="Selecione um usuário"
				/>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">Criador</label>
				<v-select
					class="mt-1 w-full bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					v-model="data.criador"
					:options="criadores"
					label="nome"
					placeholder="Selecione quem criou"
				/>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">PC Emulador</label>
				<CampoDeTexto class="mt-1 w-full h-9" v-model="data.emulador" tipo="text" holder="Pesquise pelo emulador" />
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="data.exatoEmulador"
						type="checkbox"
						:disabled="data.naoContemEmulador"
						@input="() => (data.exatoEmulador = !data.exatoEmulador)"
					/>
					Exato
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="data.naoContemEmulador"
						type="checkbox"
						:disabled="data.exatoEmulador"
						@input="() => (data.naoContemEmulador = !data.naoContemEmulador)"
					/>
					Não contém
				</label>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">PC Socket</label>
				<CampoDeTexto class="mt-1 w-full h-9" v-model="data.socket" tipo="text" holder="Pesquise pelo socket" />
				<label class="font-medium dark:text-zinc-200">
					<input class="ml-1 my-2" :checked="data.exatoSocket" type="checkbox" :disabled="data.naoContemSocket" @input="() => (data.exatoSocket = !data.exatoSocket)" />
					Exato
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="data.naoContemSocket"
						type="checkbox"
						:disabled="data.exatoSocket"
						@input="() => (data.naoContemSocket = !data.naoContemSocket)"
					/>
					Não contém
				</label>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">De</label>
				<input
					type="date"
					@input="event => (data.de = event.target.value)"
					:value="data.de"
					class="mt-1 w-full border dark:bg-zinc-800 dark:border-zinc-700 dark:text-white border-gray-200 rounded-md text-center px-2 h-9"
				/>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">Até</label>
				<input
					type="date"
					@input="event => (data.ate = event.target.value)"
					:value="data.ate"
					class="mt-1 w-full border dark:bg-zinc-800 dark:border-zinc-700 dark:text-white border-gray-200 rounded-md text-center px-2 h-9"
				/>
			</div>
			<div class="col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">Buscar</label>
				<button
					type="submit"
					class="mt-1 py-2 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Buscar
				</button>
			</div>
			<div class="col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">Copiar todos os números</label>
				<button
					type="button"
					@click="copiarTodosOsNumeros()"
					class="mt-1 py-2 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Copiar números
				</button>
			</div>
			<div class="col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">Selecionar contas listadas</label>
				<button
					type="button"
					@click="useModal('modalSelecionarContasBanidos', $vfm, false)"
					class="mt-1 py-2 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Selecionar contas
				</button>
			</div>
			<div class="col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2">
				<label class="block dark:text-zinc-200 text-zinc-500 font-bold">Download</label>
				<a
					type="button"
					target="_blank"
					:href="`${url}/v1/zap/banidos/download/?token=${getToken()}&usuario=${data.usuario}&de=${data.de}&ate=${data.ate}`"
					class="mt-1 py-2 text-center dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
					>Download</a
				>
			</div>
		</form>

		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full sm:text-left divide-y divide-gray-200 dark:divide-zinc-200" v-if="lista.length > 0">
				<thead class="select-none">
					<tr class="text-gray-400 dark:text-zinc-200 text-sm sm:text-base">
						<th class="px-2 py-3">TELEFONE</th>
						<th class="px-2 py-3">E-MAIL</th>
						<th class="px-2 py-3">ENVIOS</th>
						<th class="px-2 py-3">DATA</th>
						<th class="px-2 py-3">ÚLTIMO SCAN</th>
						<th class="px-2 py-3">CRIADOR</th>
						<th class="px-2 py-3">MOVIMENTAÇÃO</th>
						<th class="px-2 py-3">LOCAL</th>
						<th class="px-2 py-3">HISTÓRICO</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="font-bold hover:bg-gray-200 dark:hover:bg-zinc-800 text-sm" v-for="el in lista" :key="el._id">
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							<p @click="copiaNumero($event)" class="cursor-pointer active:text-blue-500">{{ el.telefone ? el.telefone : "Sem telefone" }}</p>
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							{{ el.usuario && el.usuario.email ? el.usuario.email : "Problema ao buscar email" }}
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							{{ el.envios }}
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							<p class="text-gray-700 dark:text-zinc-500 font-bold">{{ formataData(el.zap.data, "DD/MM/YYYY HH:mm") }} - {{ getInfoDate(el.diasAtivo) }}</p>
							<p class="text-gray-700 dark:text-zinc-500 font-bold">Último Ping: {{ formataData(el.zap.lastPingWeb, "DD/MM/YYYY HH:mm") }}</p>
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							{{ el.zap && el.zap.novoScan ? formataData(el.zap.novoScan, "DD/MM/YYYY HH:mm") : "" }}
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							{{ el.criador && el.criador.nome ? el.criador.nome : "" }}
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							<span v-if="el.zap.historico && el.zap.historico.length">
								Última alteração de conta: {{ formataData(el.zap.historico.at(-1).data, "DD/MM/YYYY HH:mm") }}
							</span>
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							<p class="font-semibold text-zinc-800 dark:text-zinc-500">
								Socket: <span class="font-semibold text-blue-500">{{ el.pc }}</span>
							</p>
							<p class="font-semibold text-zinc-800 dark:text-zinc-500 mt-1">
								Emulador:
								<span class="font-semibold text-blue-500">{{ el.pcEmulador }}</span>
							</p>
						</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							<button
								@click="router.push(`/admin/numeros/historico/${el.zap._id}`)"
								class="px-2 py-1 my-1 mx-1 bg-indigo-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-indigo-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-indigo-800 hover:text-opacity-50"
							>
								Histórico
							</button>
							<a
								:href="`${url}/v1/zap/analiticoEnvios/${el.zap._id}/?token=${getToken()}`"
								type="button"
								class="px-2 py-1 my-1 mx-1 bg-yellow-500 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-yellow-700 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-yellow-700 hover:text-opacity-50"
							>
								Analítico
							</a>
						</td>
					</tr>
				</tbody>
			</table>
			<span v-else>
				<p class="text-center font-semibold text-zinc-500 dark:text-zinc-200">Opa, não temos banidos {{ ":)" }}</p>
			</span>
		</div>
		<ModalSelecionarContasBanidos />
	</div>
</template>
<script setup>
	import {ref, computed, watch, onBeforeMount, inject} from "vue";
	import {getToken} from "@/services/auth.js";
	import VueApexCharts from "vue3-apexcharts";
	import {useStore} from "vuex";
	import {pega_usuarios} from "@/store/typeActions";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import ModalSelecionarContasBanidos from "./modalSelecionarContas.vue";
	import moment from "moment";
	import api from "@/services/api.js";
	import {toast} from "@/hooks/toast";
	import {useRouter} from "vue-router";
	import {formataData} from "@/hooks/formatDate";
	import useModal from "@/hooks/useModal";

	const data = ref({
		de: moment().format("YYYY-MM-DD"),
		ate: moment().format("YYYY-MM-DD"),
		usuario: null,
		criador: null,
		emulador: "",
		socket: "",
		exatoEmulador: false,
		exatoSocket: false,
		naoContemEmulador: false,
		naoContemSocket: false,
		grafico: {
			opcoes: ["Banidos", "Criados x Banidos"],
			selecionado: "Criados x Banidos",
		},
	});
	const banidos = ref({
		criados: 0,
		criadosPorBanidos: 0,
		total: 0,
		totalGeral: 0,
	});
	const contas = ref({
		banidos0a7: 0,
		banidos8a20: 0,
		banidos21a40: 0,
		banidosmaiores40: 0,
		contasSelecionadas: [],
	});
	const criadores = ref([]);
	const lista = ref([]);
	const numerosParaCopiar = ref([]);
	const grafico = ref({options: {}, series: []});
	const $vfm = inject("$vfm");
	const usuarios = computed(() => store.state.usuarios);
	const router = useRouter();
	const store = useStore();
	const url = `${process.env.VUE_APP_API}`;

	const fetchDataZaps = async () => {
		const response = await api.post(`/v1/zap/list-banidos`, {...data.value, all: true});

		lista.value = response.data.data;
		banidos.value = response.data;
		numerosParaCopiar.value = lista.value.map(item => item.telefone);
	};

	const getGrafico = async () => {
		const response = await api.post(`v1/zap/grafico-banidos`, {grafico: data.value.grafico.selecionado});
		grafico.value = response.data;
	};

	const buscaUsuarios = async () => {
		const responseUsers = await api.post(`/v1/users/list`, {contaCriacao: true});
		criadores.value = responseUsers.data.data;

		await store.dispatch(pega_usuarios, false);
	};

	const buscarBanidosPorContas = async () => {
		const response = await api.post("v1/zap/list-banidos/contas", {de: data.value.de, ate: data.value.ate});
		contas.value = response.data;
	};

	const buscar = async () => await Promise.all([getGrafico(), fetchDataZaps(), buscarBanidosPorContas()]);
	const getInfoDate = dias => `(Ficou ${dias} dia${dias > 1 ? "s" : ""} ativo)`;

	const copiaNumero = event => {
		navigator.clipboard.writeText(event.target.innerText);
		toast("Copiado!", "info");
	};

	const copiarTodosOsNumeros = () => {
		const numerosFormatados = numerosParaCopiar.value.join("\n");
		navigator.clipboard.writeText(numerosFormatados);
		toast("Copiado!", "info");
	};

	watch(
		() => data.value.grafico,
		async () => {
			if (data.value.grafico.selecionado !== null) await getGrafico();
		},
		{deep: true}
	);

	onBeforeMount(async () => await Promise.all([buscar(), buscaUsuarios()]));
</script>
