<!-- src/components/Timer.vue -->
<template>
  <div>
    <p>{{ minutes }}:{{ seconds < 10 ? '0' : '' }}{{ seconds }}</p>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';

export default {
  props: {
    modelValue: {
      type: Number,
      default: 120, // 2 minutos em segundos
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const time = ref(props.modelValue);
    let interval = null;

    const minutes = computed(() => Math.floor(time.value / 60));
    const seconds = computed(() => time.value % 60);

    const startTimer = () => {
      interval = setInterval(() => {
        if (time.value > 0) {
          time.value -= 1;
          emit('update:modelValue', time.value);
        } else {
          clearInterval(interval);
        }
      }, 1000);
    };

    watch(() => props.modelValue, (newValue) => {
      time.value = newValue;
    });

    onMounted(() => {
      startTimer();
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    return {
      minutes,
      seconds,
    };
  },
};
</script>

<style scoped>
p {
  font-size: 2rem;
  text-align: center;
}
</style>