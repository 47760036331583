import { isAuthenticated, getUsuario } from '@/services/auth.js';
import { toast } from "@/hooks/toast";

const autorizado = () => getUsuario().monitoramento || getUsuario().financeiro;

export const beforeEach = (to, from, next) => {
    if (!to.meta.rotaPublica && !isAuthenticated()) {
        toast('Desconectado! Token não encontrado.', 'error');

        return next({ name: 'Login' });
    }

    if (to.meta.monitoramento && !autorizado()) {
        return next({ path: '/admin/numeros' });
    }


    if (to.name === 'Login' && isAuthenticated()) {
        return next({ path: '/admin' });
    }

    return next();
}
