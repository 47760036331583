<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Códigos de números estrangeiros" />
		<div class="my-5 p-8 overflow-auto grid grid-cols-3 gap-5" :class="{'grid-cols-1': list.length === 0}">
			<div
				v-if="list.length > 0"
				v-for="(grupo, index) in list"
				class="col-span-2"
				:class="{'xl:col-span-1': usuario.monitoramento, 'mx-auto w-2/3': !usuario.monitoramento}"
			>
				<div>
					<h1 class="text-zinc-800 dark:text-gray-300 font-bold mb-2 w-full" :class="{'cursor-pointer': usuario.monitoramento}" @click="alterarNomeGrupo(grupo[0])">
						{{ grupo[0]?.grupo.nome }} {{ usuario.monitoramento ? "" : "- Altere a ordem dos seus códigos" }}
					</h1>
					<draggable v-model="list[index]" group="people" @start="drag = true" @end="drag = false" @change="evt => onChange(evt, index)" item-key="_id">
						<template #item="{element}">
							<div class="bg-zinc-400 dark:bg-zinc-700 text-black p-4 my-2 rounded shadow cursor-move relative">
								<span class="absolute top-2 right-2 cursor-pointer text-red-500" @click="remove(element)" v-if="usuario.monitoramento">
									<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 256 256">
										<path
											d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"
										></path>
									</svg>
								</span>
								<p class="my-2 w-full">
									<span class="text-zinc-800 dark:text-gray-300 font-bold"> DDDI (DDD Internacional): </span>
									<span class="text-blue-600 my-2">{{ element.DDDI }}</span>
								</p>
								<p class="my-2 w-full">
									<span class="text-zinc-800 dark:text-gray-300 font-bold"> Código (API): </span>
									<span class="text-blue-600 my-2">{{ element.codigoPais }}</span>
								</p>
								<p class="my-2 w-full">
									<span class="text-zinc-800 dark:text-gray-300 font-bold"> País: </span>
									<span class="text-blue-600 my-2">{{ element.pais }}</span>
								</p>
								<p class="my-2 w-full">
									<span class="text-zinc-800 dark:text-gray-300 font-bold"> Ordem{{ usuario.monitoramento ? " (no grupo)" : "" }}: </span>
									<span class="text-blue-600 my-2">{{ element.ordem }}</span>
								</p>
								<div class="flex gap-2" v-if="usuario.monitoramento">
									<span class="text-zinc-800 dark:text-gray-300 font-bold"> Usuários: </span>
									<span class="text-blue-600 flex gap-2" v-for="user in element.grupo.usuarios">{{ user.nome }}</span>
								</div>
							</div>
						</template>
					</draggable>
				</div>
			</div>
			<div class="col-span-3 grid grid-cols-4 gap-2 xl:gap-4" v-if="usuario.monitoramento">
				<form class="col-span-4 xl:my-2 xl:col-span-2 w-full" @submit.prevent="cadastrarNovoCodigo">
					<div class="flex flex-col gap-5">
						<div>
							<h2 class="font-semibold text-xl text-header dark:text-white">Cadastrar novo código</h2>
							<h2 class="font-semibold text-sm text-blue-600">A ordem é preenchida automáticamente</h2>
						</div>
						<div>
							<label class="block text-header dark:text-white">Código do número (API)</label>
							<CampoDeTexto :required="true" v-model="formCadastroCodigo.codigoPais" class="w-full" tipo="text" holder="Apenas o código" />
						</div>
						<div>
							<label class="block text-header dark:text-white">DDDI</label>
							<CampoDeTexto :required="true" v-model="formCadastroCodigo.DDDI" class="w-full" tipo="text" holder="Apenas o DDDI" />
						</div>
						<div>
							<label class="block text-header dark:text-white">País</label>
							<CampoDeTexto :required="true" v-model="formCadastroCodigo.pais" class="w-full" tipo="text" holder="País para identificação" />
						</div>
						<div>
							<label class="block text-header dark:text-white">Grupo</label>
							<v-select
								v-model="formCadastroCodigo.grupo"
								:options="grupos"
								:reduce="option => option._id"
								label="nome"
								placeholder="Selecione um grupo"
								class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
							/>
						</div>
						<button
							type="submit"
							class="bg-green-600 col-span-2 w-2/3 xl:w-1/3 mx-auto mt-4 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
						>
							Cadastrar
						</button>
					</div>
				</form>
				<form class="col-span-4 xl:my-2 xl:col-span-2 w-full" @submit.prevent="cadastrarNovoGrupo">
					<div class="flex flex-col gap-5">
						<div>
							<h2 class="font-semibold text-xl text-header dark:text-white">Cadastrar novo grupo</h2>
							<h2 class="font-semibold text-sm text-blue-600">É necessário adicionar usuários no grupo</h2>
						</div>
						<div>
							<label class="block text-header dark:text-white">Nome do grupo</label>
							<CampoDeTexto :required="true" v-model="formCadastroGrupo.nome" class="w-full" tipo="text" holder="Nome para descrição" />
						</div>
						<div>
							<label class="block text-header dark:text-white">Usuários</label>
							<v-select
								v-model="formCadastroGrupo.usuarios"
								:options="usuarios"
								:reduce="option => option._id"
								multiple
								label="email"
								placeholder="Selecione um usuário"
								class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
							/>
						</div>
						<button
							type="submit"
							class="bg-green-600 col-span-2 w-2/3 xl:w-1/3 mx-auto mt-4 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
						>
							Cadastrar
						</button>
					</div>
				</form>
				<form class="col-span-4 xl:my-2 xl:col-span-2 w-full" @submit.prevent="moverCodigo">
					<div class="flex flex-col gap-5">
						<div>
							<h2 class="font-semibold text-xl text-header dark:text-white">Mover código para outro grupo</h2>
							<h2 class="font-semibold text-sm text-blue-600">Caso o grupo esteja vazio e não liste na tela</h2>
						</div>

						<div>
							<label class="block text-header dark:text-white">Código</label>
							<v-select
								v-model="formMoverCodigo.codigo"
								:options="codigos"
								:reduce="option => option._id"
								label="pais"
								placeholder="Selecione o código para mover"
								class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
							/>
						</div>
						<div>
							<label class="block text-header dark:text-white">Grupo</label>
							<v-select
								v-model="formMoverCodigo.grupo"
								:options="grupos"
								:reduce="option => option._id"
								label="nome"
								placeholder="Selecione um grupo"
								class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
							/>
						</div>
						<button
							type="submit"
							class="bg-green-600 col-span-2 w-2/3 xl:w-1/3 mx-auto mt-4 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
						>
							Mover código
						</button>
					</div>
				</form>
				<form class="col-span-4 xl:my-2 xl:col-span-2 w-full" @submit.prevent="moverUsuario">
					<div class="flex flex-col gap-5">
						<div>
							<h2 class="font-semibold text-xl text-header dark:text-white">Mover usuário para outro grupo</h2>
							<h2 class="font-semibold text-sm text-blue-600">O usuário vai ser removido do grupo atual</h2>
						</div>

						<div>
							<label class="block text-header dark:text-white">Usuário</label>
							<v-select
								v-model="formMoverUsuario.usuarios"
								:options="usuarios"
								:reduce="option => option._id"
								label="nome"
								multiple
								placeholder="Selecione os usuários"
								class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
							/>
						</div>
						<div>
							<label class="block text-header dark:text-white">Grupo</label>
							<v-select
								v-model="formMoverUsuario.grupo"
								:options="grupos"
								:reduce="option => option._id"
								label="nome"
								placeholder="Selecione um grupo"
								class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
							/>
						</div>
						<button
							type="submit"
							class="bg-green-600 col-span-2 w-2/3 xl:w-1/3 mx-auto mt-4 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
						>
							Mover usuário
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script setup>
	import {toast} from "@/hooks/toast";
	import {ref, onBeforeMount, computed} from "vue";
	import {useStore} from "vuex";
	import draggable from "vuedraggable";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import api from "@/services/api.js";
	import Swal from "sweetalert2";

	const list = ref([]);
	const usuarios = ref([]);
	const codigos = ref([]);
	const grupos = ref([]);
	const drag = ref(false);
	const formCadastroCodigo = ref({
		codigoPais: "",
		DDDI: "",
		pais: "",
		grupo: null,
	});
	const formMoverUsuario = ref({
		usuarios: [],
		grupo: null,
	});
	const formMoverCodigo = ref({
		codigo: null,
		grupo: null,
	});
	const formCadastroGrupo = ref({
		nome: "",
		usuarios: [],
	});
	const store = useStore();
	const usuario = computed(() => store.state.usuario);

	const getLista = async () => {
		const response = await api.get("/v1/zap/listarCodigos");

		list.value = response.data.data;
	};

	const getCodigosParaMover = async () => {
		const response = await api.get("/v1/zap/listarCodigosParaMover");
		codigos.value = response.data.data;
	};

	const getGrupos = async () => {
		const response = await api.get("/v1/zap/listarGrupos");
		grupos.value = response.data.data;
	};

	const getUsuarios = async () => {
		const response = await api.post(`/v1/users/list`, {root: true});
		usuarios.value = response.data.data;
	};

	const remove = async element => {
		if (!usuario.value.monitoramento) {
			return;
		}

		const {_id, ordem} = element;

		const {isConfirmed} = await Swal.fire({
			title: "Tem certeza que deseja remover este código?",
			text: `Esta ação não pode ser desfeita`,
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Sim, remover",
			cancelButtonText: "Cancelar",
		});

		if (!isConfirmed) {
			return;
		}

		const response = await api.delete(`/v1/zap/deletarCodigo/${_id}`, {ordem});

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		await getLista();
		await getCodigosParaMover();

		return toast("Sucesso ao remover código!", "success");
	};

	const onChange = async (evt, index) => {
		const {moved, added} = evt;

		if (moved) {
			const {newIndex, oldIndex, element} = moved;

			if (oldIndex > newIndex) {
				fazUpdate(element._id.toString(), newIndex + 1);
			} else {
				fazUpdate(list.value[index][oldIndex]._id.toString(), oldIndex + 1);
			}

			return;
		}

		if (added) {
			const {newIndex, element} = added;
			const grupoQueOElementoFoiAdicionado = list.value[index];
			const grupos = grupoQueOElementoFoiAdicionado.map(g => g.grupo._id);
			const novoGrupo = grupos.find(grupo => grupo !== element.grupo._id.toString());

			fazUpdate(element._id.toString(), newIndex + 1, novoGrupo);
		}
	};

	const fazUpdate = async (id, novaOrdem, novoGrupo = null) => {
		const responseUpdate = await api.put(`/v1/zap/alterarCodigos/${id}`, {novaOrdem, novoGrupo});

		if (!responseUpdate.data.success) {
			toast(responseUpdate.data.err, "error");
			setInterval(() => {
				window.location.reload();
			}, 2000);

			return;
		}

		await getLista();
	};

	const alterarNomeGrupo = async codigo => {
		if (!usuario.value.monitoramento) {
			return;
		}
		const {grupo} = codigo;
		const inputValue = grupo.nome;

		const {value: nome} = await Swal.fire({
			title: "Alterar nome do grupo",
			input: "text",
			inputLabel: "Novo nome para o grupo",
			inputValue,
			showCancelButton: true,
			inputValidator: value => {
				if (!value) {
					return "Vai alterar pra vazio? Tá maluco?";
				}
			},
		});

		if (nome) {
			const response = await api.put(`/v1/zap/alterarNomeGrupo/${grupo._id}`, {nome});

			if (response.data.success) {
				await Promise.all([Swal.fire("Nome alterado com sucesso!", "", "success"), getLista(), getGrupos()]);
				return;
			}

			await Swal.fire(response.data.err, "", "error");
		}
	};

	const cadastrarNovoCodigo = async () => {
		if (!usuario.value.monitoramento) {
			return;
		}

		if (formCadastroCodigo.value.pais.trim() === "") {
			return toast("O campo de país é obrigatório!", "error");
		}

		if (formCadastroCodigo.value.DDDI.trim() === "") {
			return toast("O campo do DDDI é obrigatório!", "error");
		}

		if (formCadastroCodigo.value.codigoPais.trim() === "") {
			return toast("O campo de código é obrigatório!", "error");
		}

		if (formCadastroCodigo.value.grupo === null) {
			return toast("O campo de grupo é obrigatório!", "error");
		}

		const response = await api.post("/v1/zap/novoCodigo", {...formCadastroCodigo.value});

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		await getLista();

		formCadastroCodigo.value.codigoPais = "";
		formCadastroCodigo.value.pais = "";

		return toast("Sucesso ao cadastrar novo código!", "success");
	};

	const cadastrarNovoGrupo = async () => {
		if (!usuario.value.monitoramento) {
			return;
		}

		if (formCadastroGrupo.value.nome.trim() === "") {
			return toast("O campo de nome é obrigatório!", "error");
		}

		if (formCadastroGrupo.value.usuarios.length === 0) {
			return toast("O campo de usuários é obrigatório!", "error");
		}

		const response = await api.post("/v1/zap/codigoEstrangeiro/novoGrupo", {...formCadastroGrupo.value});

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		await getLista();
		await getGrupos();

		formCadastroGrupo.value.nome = "";
		formCadastroGrupo.value.usuarios = [];

		return toast("Sucesso ao cadastrar novo grupo!", "success");
	};

	const moverCodigo = async () => {
		if (!usuario.value.monitoramento) {
			return;
		}

		if (formMoverCodigo.value.grupo === null) {
			return toast("O campo de grupo é obrigatório!", "error");
		}

		if (formMoverCodigo.value.codigo === null) {
			return toast("O campo de código é obrigatório!", "error");
		}

		const response = await api.put("/v1/zap/moverCodigos", {...formMoverCodigo.value});

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		await getLista();

		formMoverCodigo.value.grupo = null;
		formMoverCodigo.value.codigo = null;

		return toast("Sucesso ao mover código!", "success");
	};

	const moverUsuario = async () => {
		if (!usuario.value.monitoramento) {
			return;
		}

		if (formMoverUsuario.value.grupo === null) {
			return toast("O campo de grupo é obrigatório!", "error");
		}

		if (formMoverUsuario.value.usuarios === null) {
			return toast("O campo de usuario é obrigatório!", "error");
		}

		const response = await api.put("/v1/zap/moverUsuarioDeGrupo", {...formMoverUsuario.value});

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		await getLista();

		formMoverUsuario.value.grupo = null;
		formMoverUsuario.value.usuarios = [];

		return toast("Sucesso ao mover usuario!", "success");
	};

	onBeforeMount(async () => {
		await Promise.all([getLista(), getUsuarios(), getGrupos(), getCodigosParaMover()]);
	});
</script>
