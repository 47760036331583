<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Auditoria" />
		<div class="my-5">
			<form @submit.prevent="fetchData()" class="grid grid-cols-12 gap-3">
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Telefone</label>
					<CampoDeTexto id="number" tipo="number" class="h-8 w-full" holder="Número de telefone" v-model="filtro.telefone" />
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Tipo de ação</label>
					<v-select v-model="filtro.acao" :options="acoes" placeholder="Nenhuma em específico" class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark" />
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Módulo</label>
					<v-select
						v-model="filtro.modulo"
						:options="modulos"
						label="nome"
						placeholder="Nenhum específico"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Usuário</label>
					<v-select
						v-model="filtro.usuario"
						:options="usuariosAdmin"
						label="nome"
						placeholder="Nenhum específico"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">
						Data de
						<input
							type="date"
							@input="event => (filtro.de = event.target.value)"
							:value="filtro.de"
							class="w-full mt-2 border border-gray-200 rounded-md text-center h-8 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
						/>
					</label>
				</div>
				<div class="col-span-12 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">
						Até
						<input
							type="date"
							@input="event => (filtro.ate = event.target.value)"
							:value="filtro.ate"
							class="w-full mt-2 border border-gray-200 rounded-md text-center h-8 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
						/>
					</label>
				</div>
				<button
					type="submit"
					class="ml-10 sm:ml-0 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Buscar
				</button>
			</form>
		</div>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200" v-if="usuarios.length > 0">
				<thead class="select-none">
					<tr class="text-header sm:text-left text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Usuário</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Status</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Ação</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Módulo</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Navegador</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Data</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-sm sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800" v-for="usuario in usuarios" :key="usuario._id">
						<th class="sm:px-2 py-3 font-medium dark:text-zinc-200">{{ usuario && usuario.usuario ? usuario.usuario.nome : "" }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ usuario.status }}</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200 text-gray-700 font-medium">{{ usuario.tipo }}</th>
						<th class="sm:px-2 py-3 text-blue-600 font-medium">{{ usuario.modulo }}</th>
						<th class="sm:px-2 py-3 text-blue-600 font-medium">{{ usuario.browser }}</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200 text-gray-600 font-medium">{{ formataData(usuario.data, "DD/MM/YYYY - HH:mm:ss") }}</th>
					</tr>
				</tbody>
			</table>
			<p v-else class="text-center font-bold text-gray-500 dark:text-zinc-200">Ops, sem usuários para essa busca!</p>

			<Pagination :paginaInicial="pagination.initialPage" :pageCount="pagination.pageCount" :fetchPagination="fetchPagination" />

			<vue-final-modal v-model="observacoesModal" name="observacoes" class="overflow-auto">
				<div id="config" class="bg-gray-100 rounded-md h-50 w-60vh mx-auto translate-y-64 p-6">
					<svg
						@click="modal(true, null)"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
					<h2 class="text-xl text-zinc-500 font-semibold mb-1">Observações</h2>
					<div class="bg-white my-5 px-2 py-2 shadow rounded-md overflow-auto" :class="observacoes.length > 0 ? 'h-96' : 'h-32'">
						<table class="w-full divide-y divide-gray-200" v-if="observacoes.length > 0">
							<thead class="select-none">
								<tr class="text-header sm:text-left text-sm sm:text-base">
									<th class="sm:px-2 py-3">Data</th>
									<th class="sm:px-2 py-3">Relatório</th>
								</tr>
							</thead>
							<tbody class="divide-y divide-gray-200">
								<tr class="text-sm my-2 hover:bg-gray-200" v-for="(observacao, index) in observacoes" :key="index">
									<td class="sm:px-2 py-3 text-gray-600">{{ formataData(observacao.data, "DD/MM/YYYY - HH:mm:ss") }}</td>
									<td class="sm:px-2 py-3 text-gray-600">{{ observacao.relatorio }}</td>
								</tr>
							</tbody>
						</table>
						<p v-else class="text-center mt-12 font-bold text-gray-500">Esse usuário ainda não fez observações!</p>
					</div>
				</div>
			</vue-final-modal>
		</div>
	</div>
</template>
<script setup>
	import {ref, computed, inject, onBeforeMount} from "vue";
	import api from "@/services/api.js";
	import Pagination from "@/components/pagination.vue";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import {pega_usuarios} from "@/store/typeActions";
	import {useStore} from "vuex";
	import {formataData} from "@/hooks/formatDate";
	import {toast} from "@/hooks/toast";

	const store = useStore();
	const usuarios = ref([]);
	const observacoes = ref([]);
	const pagination = ref({pageCount: 0, initialPage: 1});
	const filtro = ref({telefone: "", acao: "", modulo: "", usuario: null, de: "", ate: "", skip: 0, limit: 50});
	const modulos = [
		{nome: "Socket", acao: "sockets"},
		{nome: "Login", acao: "login"},
		{nome: "Criar", acao: "reportcriado"},
		{nome: "Zaps", acao: "zaps"},
		{nome: "Excluir", acao: "reportban"},
	];
	const acoes = ["Comando", "Scan", "Criação", "Edição", "Remoção", "Login"];
	const limit = 50;
	const observacoesModal = ref(false);
	const usuariosAdmin = computed(() => store.state.usuarios);
	const $vfm = inject("$vfm");

	const modal = async (fecharModal, id) => {
		if (!fecharModal) {
			const response = await api.post("/v1/users/observacoes", {id});
			if (!response.data.success) return toast(response.data.err, "error");

			observacoes.value = response.data.observacoes;
		}

		fecharModal ? $vfm.hide("observacoes") : $vfm.show("observacoes");
	};

	const fetchData = async () => {
		const data = filtro.value;
		const response = await api.post("v1/users/list-auditoria", {data});

		if (!response.data.success) return toast(response.data.err, "error");

		const perPage = response.data.total / limit;
		usuarios.value = response.data.usuarios;
		pagination.value.pageCount = Math.ceil(perPage);
	};

	const fetchPagination = async page => {
		window.scrollTo(0, 0);
		filtro.value.skip = (page - 1) * filtro.value.limit;
		pagination.value.initialPage = page;

		await fetchData();
	};

	onBeforeMount(async () => {
		await fetchData();
		await store.dispatch(pega_usuarios, true);
	});
</script>
