<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Usuários" />
		<div class="grid grid-cols-12 gap-4 mb-5">
			<div class="col-span-12 md:col-span-3 2xl:col-span-2">
				<button
					type="button"
					@click="useModal('usuarioModal', $vfm, false)"
					class="w-full bg-green-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md h-10 transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
				>
					Criar novo usuário
				</button>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-1">
				<button
					@click="router.push(`/admin/modulos`)"
					class="dark:bg-zinc-800 dark:border-zinc-900 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Módulos
				</button>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-1">
				<button
					@click="router.push(`/admin/etapas`)"
					class="dark:bg-zinc-800 dark:border-zinc-900 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Etapas
				</button>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-1">
				<button
					@click="router.push(`/admin/campanhas`)"
					class="dark:bg-zinc-800 dark:border-zinc-900 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Campanhas
				</button>
			</div>
		</div>
		<form @submit.prevent="updateQuery" class="grid grid-cols-12 gap-4">
			<div class="col-span-12 md:col-span-7 mt-2 sm:mt-0">
				<CampoDeTexto v-model="filtro" class="w-full h-10" tipo="text" holder="Buscar Usuário" />
			</div>
			<div class="col-span-12 md:col-span-3 mt-2 sm:mt-0">
				<div class="grid grid-cols-2">
					<label class="col-span-1 font-medium dark:text-zinc-200">
						<input class="ml-1 my-2" type="checkbox" :checked="clienteTrestto" @input="() => (clienteTrestto = !clienteTrestto)" />
						Cliente Trestto
					</label>
					<label class="col-span-1 font-medium dark:text-zinc-200">
						<input class="ml-1 my-2" type="checkbox" :checked="clienteNovaVida" @input="() => (clienteNovaVida = !clienteNovaVida)" />
						Cliente Nova Vida
					</label>
				</div>
			</div>
			<div class="col-span-12 md:col-span-2">
				<button
					type="submit"
					class="dark:bg-zinc-800 dark:border-zinc-900 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Buscar
				</button>
			</div>
		</form>

		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full sm:text-left divide-y divide-gray-200 dark:divide-zinc-200">
				<thead class="select-none">
					<tr class="text-header text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Nome</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">E-mail</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Usa Massa</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Permitir Envio</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Campanha Limitada à 150</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Opções</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200">
					<tr class="font-bold hover:bg-gray-200 dark:hover:bg-zinc-800 text-sm" v-for="usuario in usuarios" :key="usuario._id">
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">{{ usuario.nome }}</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">{{ usuario.email }}</td>
						<td class="sm:px-2 py-3 font-normal dark:text-zinc-200">
							<span class="dark:text-zinc-200">{{ usuario.usaMassa ? "Sim" : "Não" }}</span>
							<button
								@click="alterarUsaMassa(usuario)"
								class="bg-cyan-600 p-1 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-cyan-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-cyan-800 hover:text-opacity-50"
							>
								Alterar
							</button>
						</td>
						<td class="sm:px-2 py-3 font-normal">
							<span class="dark:text-zinc-200">{{ usuario.permitirEnvio ? "Sim" : "Não" }}</span>
							<button
								@click="alterarPermitirEnvio(usuario)"
								class="bg-cyan-600 p-1 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-cyan-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-cyan-800 hover:text-opacity-50"
							>
								Alterar
							</button>
						</td>
						<td class="sm:px-2 py-3 font-normal">
							<span class="dark:text-zinc-200">{{ usuario.limitarCampanha ? "Sim" : "Não " }}</span>
							<button
								@click="alterarLimitarCampanha(usuario)"
								class="bg-cyan-600 p-1 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-cyan-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-cyan-800 hover:text-opacity-50"
							>
								Alterar
							</button>
						</td>
						<td class="sm:px-2 py-3 font-normal text-sm">
							<button
								@click="login(usuario)"
								class="mx-1 mb-1 rounded-md px-2 py-1 text-sm border-b-2 border-r-2 border-green-800 bg-green-600 text-white transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
							>
								Login
							</button>
							<button
								@click="router.push(`/admin/editar/${usuario._id}`)"
								class="mx-1 mb-1 rounded-md px-2 py-1 text-sm bg-yellow-600 text-white border-b-2 border-r-2 border-yellow-800 transition duration-300 ease-in-out hover:shadow-md hover:bg-yellow-800 hover:text-opacity-50"
							>
								Editar
							</button>
							<button
								@click="removerUsuario(usuario)"
								class="mx-1 mb-1 rounded-md text-white px-2 py-1 text-sm border-b-2 border-r-2 border-red-800 bg-red-600 transition duration-300 ease-in-out hover:shadow-md hover:bg-red-800 hover:text-opacity-50"
							>
								Remover
							</button>
							<button
								@click="dessincronizarContatos(usuario)"
								class="mx-1 mb-1 rounded-md px-2 py-1 text-sm bg-gray-600 border-b-2 border-r-2 border-gray-800 text-white transition duration-300 ease-in-out hover:bg-gray-800 hover:shadow-md hover:text-opacity-50"
							>
								Dessincroniza Contatos
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :paginaInicial="currentPage" :pageCount="pageCount" :fetchPagination="fetchPagination" />

			<vue-final-modal v-model="novoUsuarioModal" name="usuarioModal" class="overflow-auto text-xs">
				<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-full w-3/4 md:w-1/4 mx-auto p-6 translate-y-36 sm:translate-y-42">
					<svg
						@click="useModal('usuarioModal', $vfm)"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
					<h2 class="text-xl text-header font-bold mb-1 dark:text-white">Novo Usuário</h2>
					<form class="flex flex-col items-center justify-center" @submit.prevent="cadastrarNovoUsuario()">
						<div class="w-full my-2">
							<label class="block text-sm text-header dark:text-zinc-200 font-medium">Nome</label>
							<CampoDeTexto v-model="novoUsuario.nome" class="w-full" tipo="text" :obrigatorio="true" holder="Digite o nome do usuário" />
						</div>
						<div class="w-full my-2">
							<label class="block text-sm text-header dark:text-zinc-200 font-medium">Email</label>
							<CampoDeTexto v-model="novoUsuario.email" class="w-full" tipo="text" :obrigatorio="true" holder="Digite o email do usuário" />
						</div>
						<div class="w-full my-2">
							<label class="block text-sm text-header dark:text-zinc-200 font-medium">Senha</label>
							<CampoDeTexto v-model="novoUsuario.password" class="w-full" tipo="password" :obrigatorio="true" holder="Digite a senha do usuário" />
						</div>
						<div class="w-full my-2">
							<label class="block text-sm text-header dark:text-zinc-200 font-medium">Confirme a senha</label>
							<CampoDeTexto v-model="novoUsuario.confirm_password" class="w-full" tipo="password" :obrigatorio="true" holder="Confirme a senha do usuário" />
						</div>
						<button
							type="submit"
							class="dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
						>
							Cadastrar Usuário
						</button>
					</form>
				</div>
			</vue-final-modal>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount, watch, inject} from "vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import TituloPagina from "@/components/tituloPagina.vue";
	import Pagination from "@/components/pagination.vue";
	import {toast} from "@/hooks/toast";
	import useModal from "@/hooks/useModal";
	import moment from "moment";
	import api from "@/services/api";
	import Swal from "sweetalert2";
	import {useRoute, useRouter} from "vue-router";

	const filtro = ref("");
	const clienteTrestto = ref(false);
	const clienteNovaVida = ref(false);
	const limit = 25;
	const skip = ref(0);
	const usuarios = ref([]);
	const pageCount = ref(0);
	const currentPage = ref(1);
	const novoUsuarioModal = ref(false);
	const novoUsuario = ref({nome: "", email: "", password: "", confirm_password: ""});
	const $vfm = inject("$vfm");
	const router = useRouter();
	const route = useRoute();

	const login = user => window.open(`https://dashboard.bestmessage.com.br/login?email=${user.email}&password=lembrazap12345`, "_blank");

	const fetchData = async () => {
		const res = await api.post(`/v1/users/list`, {
			data: moment().toDate(),
			busca: filtro.value,
			clienteTrestto: clienteTrestto.value,
			clienteNovaVida: clienteNovaVida.value,
			resultrel: false,
			skip: filtro.value ? 0 : skip.value,
			limit,
			root: "ambos",
		});
		usuarios.value = res.data.data;
		pageCount.value = Math.ceil(res.data.total / limit);
	};

	const updateQuery = async () => {
		skip.value = 0;
		currentPage.value = 1;
		pageCount.value = 0;

		router.push({path: `/admin/usuarios`, query: {skip: skip.value, page: currentPage.value, busca: filtro.value, m: moment().format("ss")}});
	};

	const cadastrarNovoUsuario = async () => {
		if (novoUsuario.value.password !== novoUsuario.value.confirm_password) {
			return toast("As senhas não conferem!", "error");
		}

		const response = await api.post("/novo", {
			nome: novoUsuario.value.nome,
			email: novoUsuario.value.email,
			password: novoUsuario.value.password,
			confirm_password: novoUsuario.value.confirm_password,
		});
		if (!response.data.success) return toast(response.data.err, "error");

		novoUsuario.value.nome = "";
		novoUsuario.value.email = "";
		novoUsuario.value.password = "";
		novoUsuario.value.confirm_password = "";

		await fetchData();
		toast("Usuário cadastrado com sucesso!", "success");
		useModal("usuarioModal", $vfm);
	};

	const alterarUsaMassa = async user => {
		const ativarOuDesativar = !user.usaMassa ? "Ativar" : "Desativar";
		const confirmacao = await Swal.fire({
			title: `${ativarOuDesativar} usa massa`,
			text: `Deseja ${ativarOuDesativar} usa massa?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			await api.put(`v1/users`, {_id: user._id, usaMassa: !user.usaMassa});
			await fetchData();
			toast("Sucesso!", "success");
		}
	};

	const alterarLimitarCampanha = async user => {
		const confirmacao = await Swal.fire({
			title: "Alterar Limitar Campanha",
			text: `Deseja alterar a limitação de campanha para ${user.nome}?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			const res = await api.post("/v1/users/alterarlimitarCampanha", {_id: user._id});
			if (!res.data.success) return toast(res.data.err, "error");

			await fetchData();
			toast("Sucesso!", "success");
		}
	};

	const alterarPermitirEnvio = async user => {
		const response = await api.put("/v1/users/", {
			_id: user._id,
			permitirEnvio: !user.permitirEnvio,
		});
		if (!response.data.success) return toast(response.data.err, "error");

		await fetchData();
		toast("Sucesso!", "success");
	};

	const removerUsuario = async user => {
		const confirmacao = await Swal.fire({
			title: `Excluir contato`,
			text: `Deseja remover ${user.nome} da sua lista de contatos?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			const response = await api.delete(`/v1/users/${user._id}`);
			if (!response.data.success) return toast(response.data.err, "error");

			await fetchData();
			toast("Sucesso!", "success");
		}
	};

	const dessincronizarContatos = async user => {
		const confirmacao = await Swal.fire({
			title: `Dessincronizar contatos`,
			text: `Deseja dessincronizar os contatos de ${user.nome}?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			const response = await api.post(`/v1/contatos/dessync/${user._id}`);
			if (!response.data.success) return toast(response.data.err, "error");

			await fetchData();
			toast("Sucesso!", "success");
		}
	};

	const fetchPagination = page => {
		window.scrollTo(0, 0);
		skip.value = (page - 1) * limit;
		currentPage.value = page;

		router.push({path: `/admin/usuarios/`, query: {skip: skip.value, page: page, busca: filtro.value, m: moment().format("ss")}});
	};

	const changeRouter = async () => {
		if (route.query.skip) skip.value = parseInt(route.query.skip);
		if (route.query.page) currentPage.value = parseInt(route.query.page);
		if (route.query.busca) filtro.value = route.query.busca;

		await fetchData();
	};

	onBeforeMount(async () => await changeRouter());
	watch(
		() => route,
		async () => await changeRouter(),
		{deep: true}
	);
</script>
