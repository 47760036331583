<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Campanhas" />
		<button class="my-5 p-2 rounded-full mr-4 flex items-center dark:hover:bg-zinc-600 dark:active:bg-zinc-700 hover:bg-gray-200 active:bg-gray-400 active:rounded-full" @click="() => router.back()">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
			</svg>
			<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
		</button>

		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-6 md:col-span-2">
				<input type="date" @input="event => (data = event.target.value)" :value="data" class="dark:bg-zinc-800 dark:border-zinc-700 dark:text-white w-full border border-gray-200 rounded-md text-center px-2 h-9" />
			</div>
		</div>

		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base">
				<thead class="select-none">
					<tr class="text-header sm:text-left">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Contas</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Zaps</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Total</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Sucesso</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Falhas</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Fila</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Retornos</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200" v-for="usuario in usuarios" :key="usuario._id">
					<tr
						class="text-sm text-center sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800"
						v-if="usuario.totalContatos > 0"
						:class="usuario.totalEnviadoComSucesso === 0 ? 'bg-red-200' : ''"
					>
						<td class="sm:px-2 py-3 dark:text-zinc-200">{{ usuario.usuario }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200">{{ usuario.totalZaps }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200">{{ usuario.totalContatos }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200">{{ usuario.totalEnviadoComSucesso }}</td>
						<td class="sm:px-2 py-3 text-red-500">{{ usuario.totalFalhas }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200">{{ usuario.totalZaps }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200">{{ usuario.totalRespostas }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script setup>
	import {ref, computed, watch, onBeforeMount} from "vue";
	import TituloPagina from "@/components/tituloPagina.vue";
	import {useStore} from "vuex";
	import {pega_campanhas} from "@/store/typeActions.js";
	import moment from "moment";
	import {useRouter} from "vue-router";

	const store = useStore();
	const router = useRouter();
	const data = ref(moment().format("YYYY-MM-DD"));
	const usuarios = computed(() => store.state.campanhas);
	
	onBeforeMount(async () => await store.dispatch(pega_campanhas, {data: data.value, resultrel: true}));
	watch([data], () => store.dispatch(pega_campanhas, {data: data.value, resultrel: true}));
</script>
