<template>
	<div class="my-2 mx-4 md:my-12 md:mx-">
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base overflow-auto">
				<thead class="select-none cursor-pointer">
					<tr class="text-center text-header sm:text-left">
						<th class="px-2 py-3 dark:text-zinc-200">Emulador</th>
						<th class="px-2 py-3 dark:text-zinc-200">PC Emulador</th>
						<th class="px-2 py-3 dark:text-zinc-200">Data</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-center sm:text-left text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default" v-for="(emulador, index) in emuladores" :key="index">
						<td class="mx-1 px-2 py-3 font-normal dark:text-zinc-200 text-gray-700">{{ emulador.emulador }}</td>
						<td class="mx-1 px-2 py-3 font-normal dark:text-zinc-200 text-gray-700">{{ emulador.pcEmulador }}</td>
						<td class="mx-1 px-2 py-3 font-normal dark:text-zinc-200 text-gray-700">{{ formataData(emulador.data, "DD/MM/YYYY - HH:mm", "") }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount} from "vue";
	import {useRoute} from "vue-router";
	import api from "@/services/api.js";
	import {formataData} from "@/hooks/formatDate";

	const route = useRoute();
	const emuladores = ref([]);

	const fetchData = async () => {
		const response = await api.get(`v1/emuladores/emuladoresNaoEncontrados/${route.params.id}`);
		emuladores.value = response.data.data;
	};

	onBeforeMount(async () => await fetchData());
</script>
