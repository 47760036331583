<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Envios" />
		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-12 md:col-span-3">
				<CampoDeTexto v-model="filtro" tipo="text" class="w-full h-9" holder="Buscar Telefone" />
			</div>
			<div class="col-span-12 md:col-span-3">
				<input type="date" @input="event => (data = event.target.value)" :value="data" class="w-full border border-gray-200 rounded-md text-center px-2 h-9" />
			</div>
		</div>
		<div v-if="lista.length > 0" class="bg-white my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full sm:text-left divide-y divide-gray-200">
				<thead class="select-none">
					<tr class="text-header text-sm sm:text-base">
						<th class="px-2 py-3">Telefone</th>
						<th class="px-2 py-3">Reais</th>
						<th class="px-2 py-3">Maturação</th>
						<th class="px-2 py-3">Chat</th>
						<th class="px-2 py-3">Total Envios</th>
						<th class="px-2 py-3">Validação</th>
						<th class="px-2 py-3">Total</th>
						<th class="px-2 py-3">Data</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200">
					<tr class="font-bold hover:bg-gray-200 text-sm" v-for="el in lista" :key="el._id">
						<td class="px-2 py-3 font-semibold">{{ el.telefone ? el.telefone : "Sem telefone" }}</td>
						<td class="px-2 py-3 font-semibold">{{ el.enviosReais }}</td>
						<td class="px-2 py-3 font-semibold">{{ el.enviosMaturacao }}</td>
						<td class="px-2 py-3 font-semibold">{{ el.enviosChat }}</td>
						<td class="px-2 py-3 font-semibold">{{ el.totalEnvios }}</td>
						<td class="px-2 py-3 font-semibold">{{ el.validacao }}</td>
						<td class="px-2 py-3 font-semibold">{{ el.total }}</td>
						<td class="px-2 py-3 font-semibold">{{ el.data ? formataData(el.data, "DD/MM/YYYY") : "" }}</td>
					</tr>
				</tbody>
			</table>
			<Pagination v-if="pageCount > 1" :page-count="pageCount" :click-handler="fetchPagination" />
		</div>
	</div>
</template>
<script setup>
	import {ref, watchEffect, onBeforeMount} from "vue";
	import moment from "moment";
	import api from "@/services/api.js";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import Pagination from "@/components/pagination.vue";
	import {formataData} from "@/hooks/formatDate";

	const filtro = ref("");
	const data = ref(moment().format("YYYY-MM-DD"));
	const lista = ref([]);
	const pageCount = ref(0);
	const skip = ref(0);
	const limit = 25;

	const fetchData = async () => {
		const response = await api.post(`/v1/zap/list-acompanhamento`, {
			data: moment(data.value).startOf("D").toDate(),
			busca: filtro.value,
			skip: skip.value,
			limit: limit,
		});

		pageCount.value = Math.ceil(response.data.total / limit);
		lista.value = response.data.data;
	};

	const fetchPagination = async pageNum => {
		window.scrollTo(0, 0);
		skip.value = pageNum * limit;

		await fetchData();
	};

	watchEffect(async () => await fetchData());
	onBeforeMount(async () => await fetchData());
</script>
