const TOKEN_KEY = "TOKEN";
const USUARIO_KEY = "USUARIO";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => localStorage.setItem(TOKEN_KEY, token);
export const logout = () => localStorage.removeItem(TOKEN_KEY);

export const removeUsuario = () => localStorage.removeItem(USUARIO_KEY);
export const getUsuario = () => {
    const usuario = localStorage.getItem(USUARIO_KEY);
    return JSON.parse(usuario)
};
export const setUsuario = (usuario) => localStorage.setItem(USUARIO_KEY, JSON.stringify(
    {
        user: usuario.nome,
        email: usuario.email,
        monitoramento: usuario.monitoramento,
        financeiro: usuario.financeiro
    }
));