import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import { vfmPlugin } from 'vue-final-modal';
import vSelect from 'vue-select';
import { LoadingPlugin } from 'vue-loading-overlay';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-loading-overlay/dist/css/index.css';
import 'mosha-vue-toastify/dist/style.css';
import "./assets/styles/index.css";
import "vue-select/dist/vue-select.css";

const app = createApp(App);

app.use(LoadingPlugin);
app.use(vfmPlugin);
app.use(store);
app.use(router);
app.component('VueDatePicker', VueDatePicker);
app.component('v-select', vSelect);
app.mount('#app');
