<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Módulos" />
		<div class="grid grid-cols-12 gap-4">
			<button
				class="col-span-12 sm:col-span-4 md:col-span-2 2xl:col-span-1 p-2 rounded-full mr-4 hover:bg-gray-200 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 flex items-center active:bg-gray-400 active:rounded-full"
				@click="router.back()"
			>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
					<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
				</svg>
				<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
			</button>
			<button
				@click="mostraModal(null)"
				class="w-full col-span-12 sm:col-span-6 md:col-span-4 2xl:col-span-2 bg-green-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
			>
				Novo Módulo
			</button>
		</div>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full sm:text-left divide-y divide-gray-200 dark:divide-zinc-200">
				<thead class="select-none sm:text-lg font-bold">
					<tr class="text-header text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Indice</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Nome</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">URL</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Opções</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="font-bold hover:bg-gray-200 dark:hover:bg-zinc-800 text-sm" v-for="(modulo, indice) in modulos" :key="modulo.url">
						<td class="sm:px-2 py-3 dark:text-zinc-200 font-normal">{{ indice + 1 }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200 font-normal">{{ modulo.name }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200 font-normal">{{ modulo.url }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200 font-normal">
							<button
								@click="mostraModal(modulo)"
								class="bg-yellow-600 mx-1 p-2 mb-1 md:mb-0 font-medium text-white text-sm border-b-2 border-r-2 border-yellow-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-yellow-800 hover:text-opacity-50"
							>
								Editar
							</button>
							<button
								@click="excluir(modulo._id, modulo.name)"
								class="bg-red-600 p-2 mx-1 font-medium text-white text-sm border-b-2 border-r-2 border-red-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-red-800 hover:text-opacity-50"
							>
								Remover
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<vue-final-modal v-model="showModal" name="novoModulo">
			<div class="bg-gray-100 dark:bg-dark rounded-md h-50 w-96 mx-auto translate-y-36 sm:translate-y-64 p-6">
				<svg
					@click="useModal('novoModulo', $vfm)"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
				>
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
				</svg>

				<h1 class="text-xl text-header dark:text-white font-bold mb-1">{{ novoModulo ? "Novo Módulo" : "Editar Módulo" }}</h1>
				<form @submit.prevent="salvarModulo({name, url})">
					<CampoDeTexto v-model="name" tipo="text" holder="Nome do módulo" class="w-full h-10 mt-2" :obrigatorio="true" />
					<CampoDeTexto v-model="url" tipo="text" holder="URL do módulo" class="w-full h-10 mt-2" :obrigatorio="true" />
					<button
						type="submit"
						class="bg-blue-600 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 p-2 mt-4 w-full font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
					>
						Salvar módulo
					</button>
				</form>
			</div>
		</vue-final-modal>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount, inject} from "vue";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import Swal from "sweetalert2";
	import api from "@/services/api.js";
	import {toast} from "@/hooks/toast";
	import useModal from "@/hooks/useModal";
	import {useRouter} from "vue-router";

	const $vfm = inject("$vfm");
	const showModal = ref(false);
	const name = ref("");
	const url = ref("");
	const modulos = ref([]);
	const novoModulo = ref(true);
	const modulo_edit = ref({});
	const router = useRouter();

	const mostraModal = modulo => {
		if (modulo) {
			novoModulo.value = false;
			name.value = modulo.name;
			url.value = modulo.url;
			modulo_edit.value = modulo;
		} else {
			novoModulo.value = true;
			name.value = "";
			url.value = "";
		}

		useModal("novoModulo", $vfm, false);
	};

	const salvarModulo = async data => {
		if (data.url) data.url = data.url.toLowerCase();

		let response;
		if (!novoModulo.value) response = await api.put(`/v1/acl/edit-module/${modulo_edit.value._id}`, data);
		else response = await api.post(`/v1/acl/create-module`, data);

		if (!response.data.success) return toast(response.data.err, "error");

		await buscaModulos();
		toast("Sucesso!", "success");
		useModal("novoModulo", $vfm);
	};

	const buscaModulos = async () => {
		const response = await api.post(`/v1/acl/list-modules`, {skip: 0, limit: 25});
		modulos.value = response.data.data;
	};

	const excluir = async (_id, nome) => {
		const confirmacao = await Swal.fire({
			title: `Desativar Módulo`,
			text: `Desativar ${nome} da sua lista de módulos?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			const response = await api.delete(`/v1/acl/disable-module/${_id}`);
			if (!response.data.success) return toast(response.data.err, "error");

			await buscaModulos();
			toast("Sucesso!", "success");
		}
	};

	onBeforeMount(async () => await buscaModulos());
</script>
