<template>
	<vue-final-modal v-model="modal.exibir" name="mensagemStatusModal">
		<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-56 p-6">
			<svg
				@click="useModal('mensagemStatusModal', $vfm)"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>

			<h1 class="text-xl text-header font-bold mb-2 dark:text-white">Alterar status da mensagem</h1>

			<form @submit.prevent="alterarStatusMsg" class="grid grid-cols-12">
				<CampoDeTexto v-model="modal._id" class="col-span-8" holder="Digite o _id da mensagem" :obrigatorio="true" tipo="text" />

				<label class="font-medium col-span-12 my-2 text-xl text-header dark:text-zinc-200">Selecione o status</label>

				<label class="font-medium col-span-8 my-2 text-red-500">
					<input type="radio" value="0" v-model="modal.status" />
					Error
				</label>

				<label class="font-medium col-span-8 my-2 text-green-500">
					<input type="radio" value="1" v-model="modal.status" />
					Send
				</label>

				<label class="font-medium col-span-8 my-2 text-green-800">
					<input type="radio" value="3" v-model="modal.status" />
					Delivered
				</label>

				<label class="font-medium col-span-8 my-2 text-blue-700">
					<input type="radio" value="4" v-model="modal.status" />
					Viewed
				</label>

				<button
					type="submit"
					class="dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-700 col-span-8 mt-8 text-white rounded-md w-72 px-5 py-2 hover:bg-blue-600 active:bg-blue-700"
				>
					Alterar Status
				</button>
			</form>
		</div>
	</vue-final-modal>
</template>
<script setup>
	import {ref, inject} from "vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import api from "@/services/api";
	import {toast} from "@/hooks/toast";
	import useModal from "@/hooks/useModal";

	const $vfm = inject("$vfm");
	const modal = ref({
		exibir: false,
		_id: "",
		status: 3,
	});

	const alterarStatusMsg = async () => {
		const response = await api.put("/v1/zap/alterarStatusMsg", {_id: modal.value._id, status: modal.value.status});

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		toast(`Sucesso! Novo status da mensagem: ${response.data.status}`, "success");
		useModal("mensagemStatusModal", $vfm);
		modal.value._id = "";
		modal.value.status = 3;
	};
</script>
