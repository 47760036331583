<template>
	<vue-final-modal v-model="showModal" name="baixarVolumeLzv2" class="overflow-auto">
		<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-auto w-96 mx-auto translate-y-36 p-6">
			<svg
				@click="useModal('baixarVolumeLzv2', $vfm)"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>

			<h3 class="text-lg font-semibold text-header dark:text-white 700 mb-3">
				Volume LZV2: <span class="text-gray-500">{{ volume }}</span>
			</h3>
			<div class="grid grid-cols-12 gap-4">
				<div class="col-span-12">
					<label class="flex gap-5 text-header dark:text-white text-sm mb-2">
						Enviar de todos os usuários (hoje)
						<input :checked="enviarDosUsuariosDeHoje" type="checkbox" @input="() => (enviarDosUsuariosDeHoje = !enviarDosUsuariosDeHoje)" />
					</label>
				</div>
				<div class="col-span-12">
					<label class="block text-header dark:text-white text-sm mb-2">Baixar deste usuário</label>
					<v-select
						v-model="usuario_select"
						:options="usuarios_select"
						:reduce="option => option._id"
						multiple
						label="email"
						placeholder="Selecione um usuário"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12" v-if="usuario_select && usuario_select.length === 1">
					<label class="block text-header dark:text-white text-sm mb-2">Escolha o Centro de Custo</label>
					<v-select
						v-model="centroDeCusto"
						:options="centrosDeCusto"
						label="nome"
						placeholder="Nenhum específico"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12">
					<label class="block text-header dark:text-white text-sm mb-2">Quantidade baixar</label>
					<CampoDeTexto tipo="number" v-model="qtdBaixar" class="w-full h-10" holder="Selecione a quantidade" />
				</div>
				<a
					:href="`${url}/baixarVolumeLZV2/${qtdBaixar}?usuario=${
						usuario_select && usuario_select.length > 0 ? encodeURIComponent(JSON.stringify(usuario_select)) : ''
					}&centrocusto=${centroDeCusto && centroDeCusto._id ? centroDeCusto._id : ''}`"
					target="_blank"
					class="px-4 col-span-12 my-5 py-2 w-full rounded-md text-center text-white text-sm font-medium bg-blue-600 dark:bg-zinc-800 p-2 border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					Baixar volume
				</a>
			</div>
		</div>
	</vue-final-modal>
</template>
<script setup>
	import {computed, ref, watch, onBeforeMount, inject} from "vue";
	import {useStore} from "vuex";
	import {pega_usuarios} from "@/store/typeActions.js";
	import useModal from "@/hooks/useModal";
	import api from "@/services/api.js";
	import CampoDeTexto from "@/components/campoDeTexto.vue";

	const store = useStore();
	const showModal = ref(false);
	const enviarDosUsuariosDeHoje = ref(false);
	const volume = ref(0);
	const qtdBaixar = ref(500);
	const centrosDeCusto = ref([]);
	const centroDeCusto = ref(null);
	const usuario_select = ref(null);
	const usuarios_select = computed(() => store.state.usuarios);
	const clientesQueEnviaramHoje = computed(() => store.state.contas);
	const $vfm = inject("$vfm");
	const url = `${process.env.VUE_APP_API}`;

	const buscaCentrosDeCusto = async usuario => {
		const response = await api.post(`/v1/centrocusto/list`, {usuario: usuario});
		centrosDeCusto.value = response.data.data;
	};

	onBeforeMount(async () => {
		await store.dispatch(pega_usuarios, false);

		const reqVolume = await api.post("/v1/campanhasLZV2/volumeLZV2");
		volume.value = reqVolume.data;
	});

	watch([usuario_select], async () => {
		await buscaCentrosDeCusto(usuario_select.value);
		if (centroDeCusto) centroDeCusto.value = null;
	});

	watch([enviarDosUsuariosDeHoje], () => {
		if (enviarDosUsuariosDeHoje.value) {
			usuario_select.value = clientesQueEnviaramHoje.value.map(cliente => cliente.usuario._id);
		} else {
			usuario_select.value = null;
		}
	});
</script>
