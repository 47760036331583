<template>
	<vue-final-modal v-model="modal.exibir" name="modalEnvioCampanha">
		<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-56 p-6">
			<svg
				@click="useModal('modalEnvioCampanha', $vfm)"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>
			<h1 class="text-xl text-header font-bold mb-2 dark:text-white">Envio campanha</h1>
			<form @submit.prevent="enviarCampanha" class="grid grid-cols-12">
				<label class="font-medium col-span-12 my-2 text-lg text-header dark:text-zinc-200">Selecione o volume de envio</label>
				<CampoDeTexto v-model="modal.quantidade" class="col-span-8" holder="Digite a quantidade de envios" :obrigatorio="true" tipo="number" />

				<label class="font-medium col-span-12 my-2 text-lg text-header dark:text-zinc-200">Digite o id da campanha</label>
				<CampoDeTexto v-model="modal._id" class="col-span-8" holder="Insira o id da campanha aqui" :obrigatorio="true" tipo="text" />

				<button
					type="submit"
					class="rounded-md px-5 py-2 mt-8 col-span-12 md:col-span-8 2xl:col-span-5 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-700 text-white hover:bg-blue-600 active:bg-blue-700"
				>
					Confirmar
				</button>
			</form>
		</div>
	</vue-final-modal>
</template>
<script setup>
	import useModal from "@/hooks/useModal";
	import {toast} from "@/hooks/toast";
	import api from "@/services/api";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import {ref, inject} from "vue";

	const modal = ref({exibir: false, quantidade: 10, _id: ""});
	const $vfm = inject("$vfm");

	const enviarCampanha = async () => {
		const response = await api.post("v1/zap/enviarQtdCampanha", modal.value);

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		useModal("modalEnvioCampanha", $vfm);
		modal.value = {exibir: false, quantidade: 10, _id: ""};
		return toast("Sucesso ao reenviar quantidade de campanha", "success");
	};
</script>
