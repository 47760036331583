<template>
	<div class="m-6">
		<button
			class="my-5 p-2 rounded-full mr-4 hover:bg-gray-200 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 flex items-center active:bg-gray-400 active:rounded-full"
			@click="() => router.back()"
		>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
			</svg>
			<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
		</button>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200" v-if="envios.length > 0">
				<thead class="select-none">
					<tr class="text-header sm:text-left text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Data</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Cliente</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Total</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Total Enviado</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Total Real</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Total Maturação</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Total Falhas</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-sm sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800" v-for="(envio, index) in envios" :key="index">
						<td class="sm:px-2 py-3 dark:text-zinc-200 text-gray-700">{{ formataData(envio._id) }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200 text-gray-700">{{ envio.conta }}</td>
						<td class="sm:px-2 py-3 text-blue-700">{{ envio.total ? envio.total.toLocaleString() : 0 }}</td>
						<td class="sm:px-2 py-3 text-green-700">{{ envio.totalEnviado ? envio.totalEnviado.toLocaleString() : 0 }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200 text-gray-700">{{ envio.totalReal ? envio.totalReal.toLocaleString() : 0 }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200 text-gray-700">{{ envio.totalFake ? envio.totalFake.toLocaleString() : 0 }}</td>
						<td class="sm:px-2 py-3 dark:text-zinc-200 text-gray-700">{{ envio.totalFalhas ? envio.totalFalhas.toLocaleString() : 0 }}</td>
					</tr>
				</tbody>
			</table>
			<p class="text-center font-bold text-gray-500 dark:text-zinc-200" v-else>Sem histórico para esse número</p>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount} from "vue";
	import api from "@/services/api.js";
	import {toast} from "@/hooks/toast";
	import {useRoute, useRouter} from "vue-router";
	import moment from "moment";
	const envios = ref([]);
	const route = useRoute();
	const router = useRouter();

	const formataData = data => moment(data).format("DD/MM/YYYY");

	const buscaHistorico = async () => {
		const response = await api.get(`/v1/zap/historico/${route.params.id}`);
		if (!response.data.success) return toast("Não foi possível buscar o histórico desse número", "error");

		envios.value = response.data.envios;
	};

	onBeforeMount(async () => await buscaHistorico());
</script>
