<template>
	<input
		class="text-sm font-lato font-medium border rounded-md box-border py-4 px-2 dark:bg-zinc-800 dark:focus:border-zinc-900 dark:focus:ring-1 dark:focus:ring-zinc-900 dark:hover:border-zinc-500 dark:hover:ring-1 dark:hover:ring-zinc-600 dark:border-zinc-700 dark:text-zinc-400 dark:placeholder:text-zinc-500 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 hover:border-blue-300 hover:ring-1 hover:ring-blue-900"
		:type="tipo"
		:value="modelValue"
		:placeholder="holder"
		:required="obrigatorio"
		:disabled="disable"
		@input="updateValue"
	/>
</template>
<script setup>
	const {obrigatorio = false, tipo = "text", disable = false, holder, modelValue} = defineProps(["obrigatorio", "tipo", "disable", "holder", "modelValue"]);
	const emit = defineEmits();

	const updateValue = event => emit("update:modelValue", event.target.value);
</script>
