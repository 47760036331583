<template>
	<main>
		<RouterView></RouterView>
	</main>
</template>

<script setup>
	import {provide, onMounted} from "vue";
	import {useStore} from "vuex";
	import {realiza_logout} from "@/store/typeActions.js";
	import api from "@/services/api.js";

	provide("$socket", io(process.env.VUE_APP_API_SOCKET));
	const store = useStore();

	onMounted(async () => {
		if (!store.state.token) return;

		const reqMe = await api.post(`/v1/users/me`);
		if (!reqMe.data.ativo) {
			store.dispatch(realiza_logout);
		}
	});
</script>
