<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Desempenho" />
		<div class="grid grid-cols-12 gap-6">
			<div class="col-span-12">
				<div class="flex flex-col gap-10 md:gap-5">
					<v-select
						class="md:w-2/3 xl:w-1/3 bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
						v-model="data.graficoSelecionado"
						:options="opcoesDeGrafico"
						:clearable="false"
						v-if="usuarioAdmin.monitoramento"
						placeholder="Selecione um gráfico"
					/>
					<v-select
						v-if="data.graficoSelecionado === 'Individual'"
						class="md:w-2/3 xl:w-1/3 bg-white dark:bg-zinc-800 dark:text-white dark:select-dark mb-10"
						v-model="data.usuarioSelecionado"
						:options="usuarios"
						label="nome"
						placeholder="Selecione um usuário"
					/>
				</div>
				<!-- @legendClick="handleLegendClick" (event, chartContext, seriesIndex) -->
				<VueApexCharts :options="grafico.options" height="650" :series="grafico.series" type="line" />
			</div>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount, watch} from "vue";
	import {useStore} from "vuex";
	import VueApexCharts from "vue3-apexcharts";
	import TituloPagina from "@/components/tituloPagina.vue";
	import api from "@/services/api.js";

	const store = useStore();
	const opcoesDeGrafico = ["Individual", "Desempenho geral"];
	const usuarios = ref([]);
	const grafico = ref({options: {}, series: []});
	const usuarioAdmin = ref(store.state.usuario);

	const data = ref({
		graficoSelecionado: "Desempenho geral",
		usuarioSelecionado: null,
		usuarioAdmin,
	});

	const getUsuarios = async () => {
		const response = await api.post(`/v1/users/list`, {contaCriacao: true});
		usuarios.value = response.data.data;
	};

	const getGrafico = async () => {
		const response = await api.post("/v1/zap/desempenho", {...data.value});
		grafico.value = response.data;
	};

	onBeforeMount(async () => {
		await getUsuarios();
		await getGrafico();
	});

	watch([() => data.value.graficoSelecionado, () => data.value.usuarioSelecionado], async ([novoGrafico, novoUsuario], [graficoAnterior, usuarioAnterior]) => {
		if (novoGrafico === "Individual" && novoUsuario === null) {
			return;
		}

		await getGrafico();
	});
</script>
