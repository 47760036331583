const useModal = (nomeDaModal, $vfm, fechar = true, callback) => {
    if (!$vfm) {
        throw new Error("O serviço $vfm não foi encontrado. Certifique-se de que está sendo injetado corretamente.");
    }

    if (callback && typeof callback === 'function') {
        callback();
    }

    fechar ? $vfm.hide(nomeDaModal) : $vfm.show(nomeDaModal);
}

export default useModal;
