<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Etapas" />
		<button
			class="my-5 p-2 rounded-full mr-4 flex items-center dark:hover:bg-zinc-600 dark:active:bg-zinc-700 hover:bg-gray-200 active:bg-gray-400 active:rounded-full"
			@click="() => router.back()"
		>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
			</svg>
			<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
		</button>
		<div class="grid grid-cols-12 gap-4">
			<button
				@click="mostraModal(null)"
				class="w-full col-span-12 sm:col-span-6 md:col-span-4 2xl:col-span-2 bg-green-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
			>
				Nova Etapa
			</button>
			<v-select
				class="w-full col-span-12 sm:col-span-6 md:col-span-4 2xl:col-span-2 bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
				label="email"
				:options="usuarios"
				v-model="usuario"
				placeholder="Selecione um usuário"
			/>
		</div>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full sm:text-left divide-y divide-gray-200 dark:divide-zinc-200">
				<thead class="select-none text-sm sm:text-lg font-bold">
					<tr class="text-header text-sm sm:text-base">
						<th class="px-1 sm:px-2 py-3 dark:text-zinc-200">Usuário</th>
						<th class="px-1 sm:px-2 py-3 dark:text-zinc-200">Nome</th>
						<th class="px-1 sm:px-2 py-3 dark:text-zinc-200">Mín</th>
						<th class="px-1 sm:px-2 py-3 dark:text-zinc-200">Máx</th>
						<th class="px-1 sm:px-2 py-3 dark:text-zinc-200">Opção</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="font-bold hover:bg-gray-200 dark:hover:bg-zinc-800 text-sm" v-for="item in lista" :key="item._id">
						<td class="sm:px-2 py-3 font-normal text-sm sm:text-base dark:text-zinc-200">
							{{ item.usuario && item.usuario.email ? item.usuario.email : "Erro no cadastro" }}
						</td>
						<td class="sm:px-2 py-3 font-normal text-sm sm:text-base dark:text-zinc-200">{{ item.nome }}</td>
						<td class="sm:px-2 py-3 font-normal text-sm sm:text-base dark:text-zinc-200">{{ item.minimo }}</td>
						<td class="sm:px-2 py-3 font-normal text-sm sm:text-base dark:text-zinc-200">{{ item.maximo }}</td>
						<td class="sm:px-2 py-3 font-normal text-sm">
							<button
								@click="mostraModal(item)"
								class="bg-yellow-600 mx-1 p-2 mb-1 md:mb-0 font-medium text-white text-sm border-b-2 border-r-2 border-yellow-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-yellow-800 hover:text-opacity-50"
							>
								Editar
							</button>
							<button
								@click="removeEtapa(item)"
								class="bg-red-600 p-2 mx-1 font-medium text-white text-sm border-b-2 border-r-2 border-red-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-red-800 hover:text-opacity-50"
							>
								Remover
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page-count="pageCount" :fetchPagination="fetchPagination" />
			<ModalEtapas @fetchData="fetchEtapa()" :usuario="usuarioModal" />
		</div>
	</div>
</template>
<script setup>
	import {ref, watchEffect, inject, computed, onBeforeMount} from "vue";
	import {useStore} from "vuex";
	import {pega_usuarios} from "@/store/typeActions";
	import TituloPagina from "@/components/tituloPagina.vue";
	import Pagination from "@/components/pagination.vue";
	import api from "@/services/api.js";
	import Swal from "sweetalert2";
	import {toast} from "@/hooks/toast";
	import ModalEtapas from "./modalEtapas.vue";
	import {useRouter} from "vue-router";

	const store = useStore();
	const lista = ref([]);
	const pageCount = ref(0);
	const usuario = ref(null);
	const skip = ref(0);
	const $vfm = inject("$vfm");
	const limit = 25;
	const usuarioModal = ref({
		id: "",
		nome: "",
		minimo: 0,
		maximo: 0,
		usuario_modal: null,
	});
	const router = useRouter();
	const usuarios = computed(() => store.state.usuarios);

	const mostraModal = usuario => {
		if (usuario) {
			usuarioModal.value.id = usuario._id;
			usuarioModal.value.minimo = usuario.minimo;
			usuarioModal.value.maximo = usuario.maximo;
			usuarioModal.value.nome = usuario.nome;
			usuarioModal.value.usuario_modal = usuario.usuario;
		} else {
			usuarioModal.value.minimo = 0;
			usuarioModal.value.maximo = 0;
			usuarioModal.value.nome = "";
			usuarioModal.value.id = "";
			usuarioModal.value.usuario_modal = null;
		}

		$vfm.show("novaEtapa");
	};

	const fetchEtapa = async () => {
		const response = await api.post(`/v1/etapas/list`, {
			skip: skip.value,
			limit: limit,
			usuario: usuario ? usuario.value : "",
		});

		pageCount.value = Math.ceil(response.data.total / limit);
		lista.value = response.data.data;

		localStorage.setItem("usuario", JSON.stringify(usuario.value));
	};

	const fetchPagination = async numPage => {
		window.scrollTo(0, 0);
		skip.value = numPage * limit;
		await fetchEtapa();
	};

	const removeEtapa = async etapa => {
		const confirmacao = await Swal.fire({
			title: `Remover etapa`,
			text: `Remover etapa ${etapa.nome} do ${etapa.usuario.email}?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			const response = await api.delete(`/v1/etapas/${etapa._id}`);
			if (!response.data.success) return toast(response.data.err, "error");

			toast("Sucesso!", "success");
			await fetchEtapa();
		}
	};

	watchEffect(async () => await fetchEtapa());
	onBeforeMount(async () => {
		await fetchEtapa();
		await store.dispatch(pega_usuarios, false);
	});
</script>
