<template>
	<vue-final-modal v-model="modalUsuario.exibir" name="modalSelecionarContasBanidos">
		<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-56 p-6">
			<svg
				@click="useModal('modalSelecionarContasBanidos', $vfm)"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>
			<h1 class="text-xl text-header font-bold mb-2 dark:text-white">Seleção de contas para listagem</h1>
			<form @submit.prevent="alterarListagemUsuario" class="grid grid-cols-12">
				<label class="font-medium col-span-12 my-2 text-lg text-header dark:text-zinc-200">Selecione a conta</label>
				<v-select
					v-model="modalUsuario.usuario"
					:options="usuarios"
					label="email"
					placeholder="Selecione um usuário"
					class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark col-span-12"
				/>
				<button
					type="submit"
					class="rounded-md px-5 py-2 mt-8 col-span-12 text-white text-sm font-medium bg-blue-600 dark:bg-zinc-800 p-2 border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					{{
						modalUsuario.usuario && modalUsuario.usuario.listarNaTelaDeBanidos ? "Remover" : !modalUsuario.usuario ? "Selecione o usuário para confirmar" : "Adicionar"
					}}
				</button>
			</form>
		</div>
	</vue-final-modal>
</template>
<script setup>
	import {ref, onBeforeMount, inject} from "vue";
	import useModal from "@/hooks/useModal";
	import {toast} from "@/hooks/toast";
	import api from "@/services/api";

	const modalUsuario = ref({exibir: false, usuario: null});
	const usuarios = ref([]);
	const $vfm = inject("$vfm");

	const buscaUsuarios = async () => {
		const response = await api.post(`/v1/users/list`, {all: true});
		usuarios.value = response.data.data;
	};

	const alterarListagemUsuario = async () => {
		if (!modalUsuario.value.usuario) return;

		modalUsuario.value.usuario.listarNaTelaDeBanidos = !modalUsuario.value.usuario.listarNaTelaDeBanidos;

		const response = await api.put(`v1/users`, {_id: modalUsuario.value.usuario._id, listarNaTelaDeBanidos: modalUsuario.value.usuario.listarNaTelaDeBanidos});
		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		useModal("modalSelecionarContasBanidos", $vfm);

		const mensagem = `Sucesso ao ${modalUsuario.value.usuario.listarNaTelaDeBanidos ? "adicionar" : "remover"} conta da listagem de banidos!`;
		modalUsuario.value = {exibir: false, usuario: null};

		return toast(mensagem, "success");
	};

	onBeforeMount(async () => await buscaUsuarios());
</script>
