<template>
	<Paginate
		:initial-page="paginaInicial"
		:page-count="pageCount"
		:page-range="3"
		:margin-pages="2"
		:click-handler="fetchPagination"
		:prev-text="setaEsquerda"
		:next-text="setaDireita"
		:container-class="'pagination cursor-pointer select-none'"
		:page-class="'page-item'"
	/>
</template>
<script setup>
	import Paginate from "vuejs-paginate-next";
	const {paginaInicial, pageCount, fetchPagination} = defineProps(["paginaInicial", "pageCount", "fetchPagination"]);
    
	const setaEsquerda = `<svg xmlns=" http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
	               stroke="currentColor" class="w-5 h-5">
	               <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
	           </svg>`;

	const setaDireita = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
	                stroke="currentColor" class="w-5 h-5">
	                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
	            </svg>`;
</script>
