import axios from 'axios';
import { getToken } from './auth';
import Nprogress from 'nprogress';

const api = axios.create({ baseURL: process.env.VUE_APP_API });

api.interceptors.request.use(config => {
    const token = getToken();
    Nprogress.start();

    if (token) {
        config.headers.Authorization = `${token}`;
    }

    return config;
});

api.interceptors.response.use(response => {
    Nprogress.done();

    if (response.data && response.data.login) {
        return window.location.href = '/login';
    }

    return response;
},
    error => Promise.reject(error)
);

export default api;