<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Ranking" />
		<div class="grid grid-cols-12 gap-2">
			<label class="font-medium col-span-12 sm:col-span-2 dark:text-zinc-200">
				{{
					ranking_opcao !== null && (ranking_opcao.opcao === "criadosPorPeriodo" || ranking_opcao.opcao === "rankingLimpeza")
						? "De:"
						: ranking_opcao !== null && (ranking_opcao.opcao === "rankingSemanalPremio" || ranking_opcao.opcao === "rankingSemanalEnvioMensagens")
						? "Semana:"
						: "Dia:"
				}}
				<input
					v-if="ranking_opcao !== null && (ranking_opcao.opcao === 'criadosPorPeriodo' || ranking_opcao.opcao === 'rankingLimpeza')"
					type="date"
					@input="event => (query.de = event.target.value)"
					:value="query.de"
					class="w-full border border-gray-200 rounded-md text-center px-2 h-9 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
				/>
				<VueDatePicker
					v-else-if="ranking_opcao !== null && (ranking_opcao.opcao === 'rankingSemanalPremio' || ranking_opcao.opcao === 'rankingSemanalEnvioMensagens')"
					locale="pt-br"
					id="auto"
					week-picker
					:dark="true"
					v-model="query.semana"
				/>
				<input
					v-else
					type="date"
					@input="event => (query.dia = event.target.value)"
					:value="query.dia"
					class="w-full border border-gray-200 rounded-md text-center px-2 h-9 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
				/>
			</label>
			<label class="font-medium col-span-12 sm:col-span-2 dark:text-zinc-200">
				{{ ranking_opcao !== null && (ranking_opcao.opcao === "criadosPorPeriodo" || ranking_opcao.opcao === "rankingLimpeza") ? "Até:" : "Mês:" }}
				<input
					v-if="ranking_opcao !== null && (ranking_opcao.opcao === 'criadosPorPeriodo' || ranking_opcao.opcao === 'rankingLimpeza')"
					type="date"
					@input="event => (query.ate = event.target.value)"
					:value="query.ate"
					class="w-full border border-gray-200 rounded-md text-center px-2 h-9 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
				/>
				<input
					v-else
					type="month"
					max="YYYY-MM"
					@input="event => (query.mes = event.target.value)"
					:value="query.mes"
					class="w-full border border-gray-200 rounded-md text-center px-2 h-9 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
				/>
			</label>
			<label class="font-medium col-span-12 sm:col-span-4 dark:text-zinc-200">
				Usuário:
				<v-select
					v-model="query.usuario"
					class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					:options="usuarios"
					label="email"
					placeholder="Selecione um email"
				/>
			</label>
			<button
				@click="buscaRanking"
				class="col-span-12 md:col-span-2 h-9 mt-6 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
			>
				Buscar
			</button>
		</div>
		<v-select
			v-model="ranking_opcao"
			class="bg-white my-5 dark:bg-zinc-800 dark:text-white dark:select-dark"
			:options="ranking_opcoes"
			label="label"
			placeholder="Selecione uma opção de ranking"
		/>
		<div v-if="ranking_opcao !== null && ranking_opcao.opcao === 'rankingLimpeza'" class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base overflow-auto">
				<thead class="select-none cursor-pointer">
					<tr class="text-center text-header dark:text-zinc-200 sm:text-left">
						<th class="px-2 py-3">Usuário</th>
						<th class="px-2 py-3">Total de pcs limpos</th>
						<th class="px-2 py-3">Total de emuladores</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr
						class="text-center sm:text-left text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default"
						v-for="rank in ranking[ranking_opcao.opcao]"
						:key="rank._id"
					>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">{{ rank.usuario }}</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">{{ rank.pcsLimpos }}</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">{{ rank.totalEmuladores }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div
			v-if="ranking_opcao !== null && (ranking_opcao.opcao === 'rankingSemanalEnvioMensagens' || ranking_opcao.opcao === 'rankingDiarioEnvioMensagens')"
			class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto"
		>
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base overflow-auto">
				<thead class="select-none cursor-pointer">
					<tr class="text-center text-header dark:text-zinc-200 sm:text-left">
						<th class="px-2 py-3">Usuário</th>
						<th class="px-2 py-3">Total enviado</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr
						class="text-center sm:text-left text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default"
						v-for="rank in ranking[ranking_opcao.opcao]"
						:key="rank._id"
					>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">{{ rank.usuario }}</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">{{ rank.total }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div
			v-if="
				ranking_opcao !== null &&
				ranking_opcao.opcao !== 'rankingSemanalEnvioMensagens' &&
				ranking_opcao.opcao !== 'rankingDiarioEnvioMensagens' &&
				ranking_opcao.opcao !== 'rankingLimpeza'
			"
			class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto"
		>
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base overflow-auto">
				<thead class="select-none cursor-pointer">
					<tr class="text-center text-header dark:text-zinc-200 sm:text-left" v-if="ranking_opcao.mensal">
						<th class="px-2 py-3">Id</th>
						<th class="px-2 py-3">
							{{ ehMensalOnlineOuSemanalRanking(ranking_opcao.opcao) ? "Criados" : "Email" }}
						</th>
						<th class="px-2 py-3">
							{{ ehMensalOnlineOuSemanalRanking(ranking_opcao.opcao) ? "Banidos" : "Total" }}
						</th>
						<th class="px-2 py-3" v-if="ranking_opcao.opcao === 'criadosMensalOnline'">Ratio</th>
						<th class="px-2 py-3" v-if="ranking_opcao.opcao === 'rankingSemanalPremio'">Total</th>
					</tr>
					<tr class="text-center text-header dark:text-zinc-200 sm:text-left" v-else>
						<th class="px-2 py-3">Id</th>
						<th class="px-2 py-3">
							{{ ranking_opcao.opcao === "criadosHojeOnline" ? "Ativos" : "Email" }}
						</th>
						<th class="px-2 py-3" v-if="ranking_opcao.opcao !== 'criadosHoje'">
							{{ ranking_opcao.opcao === "criadosHojeOnline" ? "Excluídos" : "Total" }}
						</th>
						<th class="px-2 py-3" v-if="ranking_opcao.opcao === 'criadosHojeOnline'">Ratio</th>
						<th class="px-2 py-3" v-if="ranking_opcao.opcao === 'criadosHoje'">Telefone</th>
						<th class="px-2 py-3" v-if="ranking_opcao.opcao === 'criadosHoje'">Horário</th>
						<th class="px-2 py-3" v-if="ranking_opcao.opcao === 'criadosHojePorConta'">Números</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr
						class="text-center sm:text-left text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default"
						v-for="rank in ranking[ranking_opcao.opcao]"
						:key="rank._id"
						v-if="ranking_opcao.mensal"
					>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">{{ rank._id }}</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">
							{{ ehMensalOnlineOuSemanalRanking(ranking_opcao.opcao) ? rank.criados : rank.email }}
						</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">
							{{ ehMensalOnlineOuSemanalRanking(ranking_opcao.opcao) ? rank.banidos : rank.total }}
						</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200" v-if="ranking_opcao.opcao === 'criadosMensalOnline'">
							{{ ratio(rank.banidos, rank.criados) }}
						</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200" v-if="ranking_opcao.opcao === 'rankingSemanalPremio'">
							{{ rank.total > 0 ? rank.total : msgDeErro(rank.total, rank.criados) }}
						</td>
					</tr>
					<tr class="text-center sm:text-left text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default" v-for="rank in ranking[ranking_opcao.opcao]" v-else>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">{{ rank._id }}</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">
							{{ ranking_opcao.opcao === "criadosHojeOnline" ? rank.ativos : rank.email }}
						</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200" v-if="ranking_opcao.opcao !== 'criadosHoje'">
							{{ ranking_opcao.opcao === "criadosHojeOnline" ? rank.excluidos : rank.total }}
						</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200" v-if="ranking_opcao.opcao === 'criadosHojeOnline'">
							{{ ratio(rank.excluidos, rank.ativos) }}
						</td>
						<td v-if="ranking_opcao.opcao === 'criadosHoje'" class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">
							{{ rank.telefone }}
						</td>
						<td v-if="ranking_opcao.opcao === 'criadosHoje'" class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">
							{{ formataData(rank.horario) }}
						</td>
						<td
							v-if="ranking_opcao.opcao === 'criadosHojePorConta'"
							v-for="(numero, index) in rank.numeros"
							:key="index"
							class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200"
						>
							{{ numero }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script setup>
	import {watch, ref, onMounted, onUnmounted} from "vue";
	import TituloPagina from "@/components/tituloPagina.vue";
	import api from "@/services/api.js";
	import moment from "moment";

	const ranking_opcoes = ref([
		{mensal: false, opcao: "criadosHoje", label: "Criados hoje"},
		{mensal: false, opcao: "criadosHojePorConta", label: "Criados hoje por conta"},
		{mensal: false, opcao: "criadosHojeResumo", label: "Criados hoje resumo"},
		{mensal: false, opcao: "criadosHojeOnline", label: "Criados hoje online"},
		{mensal: false, opcao: "criadosPorPeriodo", label: "Criados por período"},
		{mensal: false, opcao: "rankingSemanalEnvioMensagens", label: "Mensagens enviadas na semana"},
		{mensal: false, opcao: "rankingDiarioEnvioMensagens", label: "Mensagens enviadas hoje"},
		{mensal: false, opcao: "rankingLimpeza", label: "Ranking de limpeza"},
		{mensal: true, opcao: "rankingHojeBanido", label: "Banidos hoje"},
		{mensal: true, opcao: "rankingSemanaBanido", label: "Banidos na semana"},
		{mensal: true, opcao: "rankingSemanalPremio", label: "Ranking da semana"},
		{mensal: true, opcao: "criadosMensalOnline", label: "Criados mensal online"},
		{mensal: true, opcao: "rankingMensalCriacao", label: "Criados mensal"},
		{mensal: true, opcao: "rankingMensalBanido", label: "Banidos mensal"},
	]);
	const ranking = ref({
		criadosHoje: [],
		criadosHojePorConta: [],
		criadosHojeResumo: [],
		criadosPorPeriodo: [],
		criadosHojeOnline: [],
		rankingHojeBanido: [],
		rankingSemanaBanido: [],
		rankingSemanalPremio: [],
		rankingSemanalEnvioMensagens: [],
		rankingDiarioEnvioMensagens: [],
		criadosMensalOnline: [],
		rankingMensalCriacao: [],
		rankingMensalBanido: [],
		rankingLimpeza: [],
	});
	const query = ref({
		usuario: null,
		dia: moment().format("YYYY-MM-DD"),
		mes: moment().format("YYYY-MM"),
		de: moment().startOf("week").format("YYYY-MM-DD"),
		ate: moment().endOf("week").format("YYYY-MM-DD"),
		semana: [moment().startOf("week").toDate(), moment().endOf("week").toDate()],
	});
	const usuarios = ref([]);
	const ranking_opcao = ref({mensal: false, opcao: "rankingDiarioEnvioMensagens", label: "Mensagens enviadas hoje"});

	const buscaRanking = async () => {
		const params = {
			usuario: query.value.usuario ? query.value.usuario._id : "",
			dia: query.value.dia,
			mes: query.value.mes,
			de: query.value.de,
			ate: query.value.ate,
			semana: query.value.semana,
		};

		const response = await api.get("/v1/zap/ranking", {params});
		ranking.value = response.data;
	};

	const buscaUsuarios = async () => {
		const responseUsers = await api.post(`/v1/users/list`, {contaCriacao: true});
		usuarios.value = responseUsers.data.data;
	};

	const ratio = (banido, criado) => {
		const divisao = banido / criado;
		if (divisao === Infinity) return "Sem números criados";

		return divisao.toFixed(2);
	};

	const formataData = data => moment(data).format("HH:mm");
	const ehMensalOnlineOuSemanalRanking = opcao => opcao === "criadosMensalOnline" || opcao === "rankingSemanalPremio";

	const msgDeErro = (total, criados) => {
		if (criados === 0) {
			return "Usuário ainda não criou essa semana";
		}

		if (total === 0 && criados > 0) {
			return "A quantidade de números banidos essa semana é a mesma de números criados";
		}

		return "A quantidade de números banidos é maior que a de números criados!";
	};

	watch([ranking_opcao], async () => {
		if (ranking_opcao.value !== null) {
			const opcao = ranking_opcao.value.opcao;
			ranking.value = {
				...ranking.value,
				[opcao]: ranking.value[opcao] || [],
			};
			await buscaRanking();
		}
	});

	let interval;

	onMounted(async () => {
		await Promise.all([buscaUsuarios(), buscaRanking()]);
		interval = setInterval(async () => {
			await Promise.all([buscaUsuarios(), buscaRanking()]);
		}, 5 * 60 * 1000);
	});

	onUnmounted(() => {
		clearInterval(interval);
	});
</script>
<style>
	.dp__theme_dark {
		--dp-background-color: #212121;
		--dp-text-color: #fff;
		--dp-hover-color: #484848;
		--dp-hover-text-color: #fff;
		--dp-hover-icon-color: #959595;
		--dp-primary-color: #005cb2;
		--dp-primary-disabled-color: #61a8ea;
		--dp-primary-text-color: #fff;
		--dp-secondary-color: #a9a9a9;
		--dp-border-radius: 6px;
		--dp-border-color: #2d2d2d;
		--dp-menu-border-color: #2d2d2d;
		--dp-border-color-hover: #aaaeb7;
		--dp-disabled-color: #737373;
		--dp-disabled-color-text: #d0d0d0;
		--dp-scroll-bar-background: #212121;
		--dp-scroll-bar-color: #484848;
		--dp-success-color: #00701a;
		--dp-success-color-disabled: #428f59;
		--dp-icon-color: #959595;
		--dp-danger-color: #e53935;
		--dp-marker-color: #e53935;
		--dp-tooltip-color: #3e3e3e;
		--dp-highlight-color: rgb(0 92 178 / 20%);
		--dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
		--dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
		--dp-range-between-border-color: var(--dp-hover-color, #fff);
	}
</style>
