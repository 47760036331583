<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Envios LZV2" />
		<div class="flex gap-10">
			<button
				class="p-2 rounded-full hover:bg-gray-200 active:bg-gray-400 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 active:rounded-full flex items-center"
				@click="() => router.back()"
			>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 dark:text-white mr-4">
					<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
				</svg>
				<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
			</button>
			<v-select
				v-model="usuario"
				:options="usuarios"
				label="nome"
				placeholder="Nenhum específico"
				class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark w-full md:w-2/3 lg:w-1/3 xl:w-1/2 2xl:w-1/3"
			/>
		</div>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full sm:text-left divide-y divide-gray-200">
				<thead class="select-none">
					<tr class="text-header text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Telefone</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Mensagem</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="font-bold hover:bg-gray-200 dark:hover:bg-zinc-800 text-sm" v-for="item in conversas" :key="item._id">
						<td
							class="sm:px-2 py-3 text-blue-700 dark:text-blue-700 cursor-pointer active:text-blue-500"
							:class="item.copiaNumero ? 'bg-green-200 dark:bg-green-900' : ''"
							@click="copiaNumero($event, item)"
						>
							{{ item.telefone }}
						</td>
						<td
							class="sm:px-2 py-3 font-normal dark:text-zinc-200 cursor-pointer"
							:class="item.copiaMensagem ? 'bg-green-200 dark:bg-green-900' : ''"
							@click="copiaMensagem($event, item)"
						>
							{{ item.mensagem }}
						</td>
					</tr>
				</tbody>
			</table>
			<button
				class="p-2 rounded-full dark:text-white hover:bg-gray-200 active:bg-gray-400 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 active:rounded-full flex items-center"
				@click="fetchPagination()"
			>
				<span class="select-none cursor-pointer text-lg">Recarregar</span>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 256 256">
					<path
						d="M224,48V96a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h28.69L182.06,73.37a79.56,79.56,0,0,0-56.13-23.43h-.45A79.52,79.52,0,0,0,69.59,72.71,8,8,0,0,1,58.41,61.27a96,96,0,0,1,135,.79L208,76.69V48a8,8,0,0,1,16,0ZM186.41,183.29a80,80,0,0,1-112.47-.66L59.31,168H88a8,8,0,0,0,0-16H40a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V179.31l14.63,14.63A95.43,95.43,0,0,0,130,222.06h.53a95.36,95.36,0,0,0,67.07-27.33,8,8,0,0,0-11.18-11.44Z"
					></path>
				</svg>
			</button>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount, computed} from "vue";
	import {toast} from "@/hooks/toast";
	import {useRouter} from "vue-router";
	import {useStore} from "vuex";
	import {pega_usuarios} from "@/store/typeActions";
	import TituloPagina from "@/components/tituloPagina.vue";
	import api from "@/services/api";

	const limit = 20;
	const conversas = ref([]);
	const pageCount = ref(0);
	const usuario = ref(null);
	const router = useRouter();
	const store = useStore();
	const usuarios = computed(() => store.state.usuarios);

	const fetchData = async () => {
		const res = await api.post(`/v1/campanhasLZV2/list-envios-clientes`, {limit, usuario: usuario.value});
		conversas.value = res.data.data;
		pageCount.value = Math.ceil(res.data.total / limit);
	};

	const fetchPagination = async () => {
		window.scrollTo(0, 0);
		await fetchData();
	};

	const copiaNumero = async (event, item) => {
		navigator.clipboard.writeText(`55${event.target.innerText}`);
		toast("Copiado!", "info");
		item.copiaNumero = true;
	};

	const copiaMensagem = async (event, item) => {
		navigator.clipboard.writeText(event.target.innerText);
		toast("Copiado!", "info");
		item.copiaMensagem = true;
		await api.put(`/v1/campanhasLZV2/confirmarEnvioLZV2/${item._id}`);
	};

	onBeforeMount(async () => {
		await store.dispatch(pega_usuarios, false);
		await fetchData();
	});
</script>
