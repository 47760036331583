import { createToast } from "mosha-vue-toastify";

export const toast = (mensagem, tipo) => {
    if (tipo === "success" || tipo === "error") {
        createToast(mensagem, {
            position: "top-right",
            timeout: 2000,
            showIcon: true,
            toastBackgroundColor: tipo === "success" ? "#2F4F4F" : "#8B0000",
            type: tipo,
            transition: "slide",
        });

        return;
    }

    createToast(mensagem, {
        showCloseButton: false,
        position: 'bottom-center',
        type: tipo,
        swipeClose: false,
        timeout: 1500,
        hideProgressBar: true,
        showIcon: true,
    });
};
