import {createRouter, createWebHistory} from "vue-router";
import Login from "@/views/auth/login.vue";
import Etapas from "@/views/etapas/etapas.vue";
import Home from "@/views/home/home.vue";
import EnviosClientes from "@/views/home/enviosClientes.vue";
import Modulo from "@/views/modulos/modulo.vue";
import Usuario from "@/views/usuarios/usuarios.vue";
import EditaUsuario from "@/views/usuarios/editar.vue";
import Numeros from "@/views/numeros/numeros.vue";
import QRCode from "@/views/numeros/qrcode.vue";
import Envios from "@/views/envios/envios.vue";
import Banidos from "@/views/banidos/banidos.vue";
import Campanhas from "@/views/campanhas/campanhas.vue";
import Historico from "@/views/numeros/historico.vue";
import Auditoria from "@/views/auditoria/auditoria.vue";
import Ranking from "@/views/numeros/ranking.vue";
import Emuladores from "@/views/emuladores/emuladores.vue";
import FluxoGeral from "@/views/home/fluxoGeral.vue";
import EmuladoresNaoEncontrados from "@/views/emuladores/emuladoresNaoEncontrados.vue";
import Cabecalho from "@/components/cabecalhoAdm.vue";
import CodigosEstrangeiros from "@/views/codigosNumerosEstrangeiros/index.vue";
import Desempenho from "@/views/desempenho/index.vue";
import NotFound from "@/views/notfound.vue";
import {beforeEach} from "./functions";

const routes = [
	{
		path: "/",
		redirect: "/login",
		meta: {rotaPublica: true},
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {rotaPublica: true},
	},
	{
		path: "/qrcode/:numero",
		name: "QRCode",
		component: QRCode,
		meta: {rotaPublica: true},
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: NotFound,
		meta: {rotaPublica: true},
	},
	{
		path: "/admin",
		component: Cabecalho,
		children: [
			{
				path: "",
				name: "Home",
				component: Home,
				meta: {monitoramento: true},
			},
			{
				path: "campanhas",
				name: "Campanhas",
				component: Campanhas,
				meta: {monitoramento: true},
			},
			{
				path: "editar/:id",
				name: "Editar_Usuario",
				component: EditaUsuario,
				meta: {monitoramento: true},
			},
			{
				path: "numeros/historico/:id",
				name: "Historico",
				component: Historico,
			},
			{
				path: "usuarios",
				name: "Usuario",
				component: Usuario,
				meta: {monitoramento: true},
			},
			{
				path: "etapas",
				name: "Etapas",
				component: Etapas,
				meta: {monitoramento: true},
			},
			{
				path: "modulos",
				name: "Modulo",
				component: Modulo,
				meta: {monitoramento: true},
			},
			{
				path: "ranking",
				name: "Ranking",
				component: Ranking,
			},
			{
				path: "desempenho",
				name: "Desempenho",
				component: Desempenho,
			},
			{
				path: "emuladores",
				name: "Emuladores",
				component: Emuladores,
				meta: {monitoramento: true},
			},
			{
				path: "emuladores/naoEncontrados/:id",
				name: "EmuladoresNaoEncontrados",
				component: EmuladoresNaoEncontrados,
				meta: {monitoramento: true},
			},
			{
				path: "auditoria",
				name: "Auditoria",
				component: Auditoria,
				meta: {monitoramento: true},
			},
			{
				path: "numeros",
				name: "Numeros",
				component: Numeros,
			},
			{
				path: "banidos",
				name: "Banidos",
				component: Banidos,
			},
			{
				path: "envios",
				name: "Envios",
				component: Envios,
				meta: {monitoramento: true},
			},
			{
				path: "fluxo",
				name: "Fluxo",
				component: FluxoGeral,
			},
			{
				path: "envios-clientes",
				name: "EnviosClientes",
				component: EnviosClientes,
			},
			{
				path: "codigos-estrangeiros",
				name: "CodigosEstrangeiros",
				component: CodigosEstrangeiros,
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
});

router.beforeEach(beforeEach);

export default router;
