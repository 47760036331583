import { createStore } from "vuex";
import api from "@/services/api.js";
import moment from 'moment';
import {
    DEFINE_USUARIOS,
    DEFINE_CONTAS,
    LOGIN,
    LOGOUT,
    DEFINE_CAMPANHAS,
    DEFINE_ATIVIDADE
} from "./typeMutations.js";
import {
    pega_contas,
    pega_usuarios,
    pega_campanhas,
    realiza_login,
    realiza_logout,
    muda_atividade
} from "./typeActions.js";
import {
    login,
    setUsuario,
    getToken,
    getUsuario,
    logout,
    removeUsuario
} from "@/services/auth.js";
import { toast } from "@/hooks/toast";

export const store = createStore({
    state: {
        token: getToken(),
        usuario: getUsuario(),
        atividade: localStorage.getItem('atividade') || '',
        contas: [],
        campanhas: [],
        usuarios: [],
    },
    mutations: {
        [LOGIN](state, { user, token }) {
            login(token);
            setUsuario(user);
            state.token = getToken();
            state.usuario = getUsuario();
        },

        [LOGOUT](_state) {
            logout();
            removeUsuario();
        },

        [DEFINE_ATIVIDADE](state, atividade) {
            state.atividade = atividade;
        },

        [DEFINE_CONTAS](state, contas) {
            state.contas = contas;
        },

        [DEFINE_CAMPANHAS](state, campanhas) {
            state.campanhas = campanhas;
        },

        [DEFINE_USUARIOS](state, usuarios) {
            state.usuarios = usuarios;
        },
    },
    actions: {
        async [pega_contas]({ commit }, { de, ate, resultrel }) {
            const resp = await api.post(`/v1/users/list-contas`, { de: moment(de).toDate(), ate: moment(ate).toDate(), resultrel: resultrel });

            commit(DEFINE_CONTAS, resp.data.resultlzv2);
        },

        async [pega_campanhas]({ commit }, { data, resultrel }) {
            const resp = await api.post(`/v1/users/list-campanhas`, { data: moment(data).toDate(), resultrel: resultrel });

            commit(DEFINE_CAMPANHAS, resp.data.resultrel);
        },

        async [pega_usuarios]({ commit }, root) {
            const responseUsers = await api.post(`/v1/users/list`, { all: true, root });

            commit(DEFINE_USUARIOS, responseUsers.data.data);
        },

        async [realiza_login]({ commit }, usuario) {
            const resp = await api.post("/login-admin", usuario);
            if (!resp.data.success) return false;

            commit(LOGIN, { user: resp.data.user, token: resp.data.token });

            const rota = resp.data.user.monitoramento || resp.data.user.financeiro ? '/admin' : '/admin/numeros';
            return { success: true, rota };
        },

        async [muda_atividade]({ commit }, atividade) {
            const usuario = getUsuario();

            const response = await api.post('v1/users/atividade', { usuario, atividade });
            if (!response.data.sucess) return toast(response.data.err, "error");

            localStorage.setItem("atividade", atividade);
            commit(DEFINE_ATIVIDADE, atividade);
        },

        [realiza_logout]({ commit }) { commit(LOGOUT) }
    },
});
