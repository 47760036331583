<template>
	<vue-final-modal v-model="showModal" name="novaEtapa">
		<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-36 sm:translate-y-64 p-6">
			<svg
				@click="useModal('novaEtapa', $vfm)"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>
			<h1 class="text-lg text-zinc-500 dark:text-white font-semibold text-center">Etapas</h1>
			<form @submit.prevent="save" class="flex flex-col">
				<label class="block my-2 text-sm text-zinc-600 dark:text-zinc-200 font-semibold">Usuário</label>
				<v-select
					v-model="usuario_modal"
					class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					:options="usuarios"
					label="email"
					placeholder="Selecione um usuário"
				/>
				<label class="block my-2 text-sm text-zinc-600 dark:text-zinc-200 font-semibold">Nome</label>
				<CampoDeTexto v-model="nome" class="w-full h-10" tipo="text" holder="Digite o nome" />
				<label class="block my-2 text-sm text-zinc-600 dark:text-zinc-200 font-semibold">Mínimo</label>
				<CampoDeTexto v-model="minimo" class="w-full h-10" tipo="number" holder="Mínimo" />
				<label class="block my-2 text-sm text-zinc-600 dark:text-zinc-200 font-semibold">Máximo</label>
				<CampoDeTexto v-model="maximo" class="w-full h-10" tipo="number" holder="Máximo" />
				<button
					type="submit"
					class="mx-auto dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-green-600 mt-5 p-2 w-full sm:1/2 md:w-1/3 xl:1/4 font-medium text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
				>
					Salvar
				</button>
			</form>
		</div>
	</vue-final-modal>
</template>
<script setup>
	import {ref, computed, onBeforeMount, onUpdated, inject} from "vue";
	import {useStore} from "vuex";
	import {pega_usuarios} from "@/store/typeActions";
	import api from "@/services/api.js";
	import useModal from "@/hooks/useModal";
	import {toast} from "@/hooks/toast";
	import CampoDeTexto from "@/components/campoDeTexto.vue";

	const $vfm = inject("$vfm");
	const usuario_modal = ref(null);
	const showModal = ref(false);
	const nome = ref("");
	const id = ref("");
	const minimo = ref(0);
	const maximo = ref(0);
	const store = useStore();
	const usuarios = computed(() => store.state.usuarios);
	const props = defineProps(["usuario"]);

	function passaProps() {
		id.value = props.usuario.id;
		minimo.value = props.usuario.minimo;
		maximo.value = props.usuario.maximo;
		nome.value = props.usuario.nome;
		usuario_modal.value = props.usuario.usuario_modal;
	}

	async function save() {
		if (!usuario_modal.value) {
			return toast("Usuário é um campo obrigatório!", "error");
		}

		const data = {
			nome: nome.value || "Sem nome cadastrado",
			minimo: minimo.value,
			maximo: maximo.value,
			usuario: usuario_modal.value._id,
		};

		let response;

		if (id.value) {
			data._id = id.value;
			response = await api.put("/v1/etapas", data);
		} else {
			response = await api.post("/v1/etapas", data);
		}

		if (!response.data.success) return toast(response.data.err, "error");

		emit("fetchData");
		toast("Salvo com sucesso!", "success");
		useModal("novaEtapa", $vfm);
	}

	onBeforeMount(async () => await store.dispatch(pega_usuarios, false));
	onUpdated(() => passaProps());
</script>
