<template>
	<vue-final-modal v-model="modal.exibir" name="modalEnvioChats">
		<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-56 p-6">
			<svg
				@click="useModal('modalEnvioChats', $vfm)"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>

			<h1 class="text-xl text-header font-bold mb-2 dark:text-white">Envio de múltiplos chats</h1>
			<form @submit.prevent="realizaEnviosDosChats()" class="grid grid-cols-12 gap-4">
				<textarea
					rows="1"
					class="mt-2 col-span-12 font-medium text-base border rounded-md box-border y-3 sm:py-5 px-2 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 hover:border-blue-300 hover:ring-1 hover:ring-blue-900 dark:bg-zinc-800 dark:focus:border-zinc-900 dark:focus:ring-1 dark:focus:ring-zinc-900 dark:hover:border-zinc-500 dark:hover:ring-1 dark:hover:ring-zinc-600 dark:border-zinc-700 dark:text-zinc-400 dark:placeholder:text-zinc-500"
					v-model="modal.ids_text"
					tipo="text"
					placeholder="Digite os ids (separado por espaço, vírgula ou quebra de linha)"
				>
				</textarea>
				<button
					type="submit"
					className="text-white my-2 sm:my-0 
                    bg-blue-700 cursor-pointer px-2 py-1 col-span-6
                    rounded-md hover:bg-blue-600 active:bg-blue-700
					dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900"
				>
					Enviar
				</button>
			</form>
		</div>
	</vue-final-modal>
</template>
<script setup>
	import {ref, inject} from "vue";
	import api from "@/services/api";
	import {toast} from "@/hooks/toast";
	import useModal from "@/hooks/useModal";
	import {useLoading} from "vue-loading-overlay";

	const modal = ref({exibir: false, ids_text: ""});
	const $loading = useLoading();
	const $vfm = inject("$vfm");
	const emit = defineEmits();

	async function realizaEnviosDosChats() {
		const ids = modal.value.ids_text.split(/[ ,\n]+/);

		if (!ids || ids.length === 0) {
			return toast("Forneça os ids para realizar os envios", "error");
		}

		const loader = $loading.show({canCancel: false, isFullPage: true});
		const response = await api.post("/v1/zap/enviosChat", {ids});
		loader.hide();

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		modal.value.ids_text = "";
		emit("envioRealizado");
		toast("Sucesso ao enviar mensagens", "success");
		useModal("modalEnvioChats", $vfm);
	}
</script>
