<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Fluxo Geral" />
		<button
			class="my-5 p-2 rounded-full mr-4 hover:bg-gray-200 flex items-center dark:hover:bg-zinc-600 dark:active:bg-zinc-700 active:bg-gray-400 active:rounded-full"
			@click="() => router.back()"
		>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
			</svg>
			<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
		</button>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Criação/Scan de números</h2>
			<p class="dark:text-zinc-200 mt-4">
				Hoje <strong class="text-blue-600">4 estão na criação</strong>, esses números são cadastrados na aba de PréCadastro enquanto
				<strong class="text-yellow-700">1 faz scan desses criados.</strong>
			</p>
			<p class="dark:text-zinc-200 mt-2">Quando os números são adicionados na plataforma (para as contas de criação) neste dia que é feito o scan.</p>
			<h3 class="text-zinc-700 dark:text-zinc-500 font-semibold">
				Quantidade de números pré cadastrados fora da dash: <span class="text-blue-600 font-semibold">{{ quantidades.criacaoEScan }}</span>
			</h3>
		</div>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Mover números: (LZ-MOVER-NUMEROS)</h2>
			<ul class="list-disc list-inside mt-4">
				<li>
					<strong class="text-green-600">Criação:</strong>
					<span class="dark:text-zinc-200"> Números criados em conta criação (contas do Pedro, José, Ana, RoboLZ) são movidos às 00:00 para conta maturação. </span>
				</li>
				<li>
					<strong class="text-purple-600">Maturação:</strong>
					<span class="dark:text-zinc-200">
						Números transferidos para a conta maturação a 10 dias e com mais de 100 envios são transferidos para as contas envios 1, envios 2, envios 3 e envios 4
						proporcionalmente.
					</span>
				</li>
				<li>
					<strong class="text-blue-600">Validadores:</strong>
					<span class="dark:text-zinc-200">
						Números das contas envios 1, envios 2, envios e envios 4 se tornam validadores quando possuem 40 dias de criação e mais de 300 envios.
					</span>
				</li>
			</ul>
		</div>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Maturação: (LZ-MATURACAO)</h2>
			<p class="dark:text-zinc-200 mt-4">
				A maturação roda a cada <strong class="text-blue-600">2h</strong> das <strong class="text-green-600">9h</strong> até <strong class="text-green-600">18h</strong> (5
				etapas) usando uma lógica que o número tem <strong class="text-blue-700">70%</strong> de chance de entrar nas etapas.
			</p>
			<p class="dark:text-zinc-200 mt-2">
				A maturação envia <strong class="text-yellow-600">3</strong> mensagens para os números da agenda (Números presentes nos PC
				<strong class="text-yellow-600">M5, I53 e M03</strong> que salvam todo número criado na plataforma).
			</p>
			<h3 class="text-zinc-700 font-semibold dark:text-zinc-500">
				Quantidade de números na maturação: <span class="text-blue-600 font-semibold">{{ quantidades.maturacao }}</span>
			</h3>
		</div>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Massivo: (LZ-CLIENTES-ENVIOS)</h2>
			<p class="dark:text-zinc-200 mt-4">
				O massivo roda atualmente, enviando <strong class="text-blue-600">2</strong> reais e <strong class="text-yellow-600">4</strong> maturação. Fazendo a distribuição
				das reais por cliente e centro de custo.
			</p>
			<ul class="list-disc list-inside mt-2">
				<li><strong class="text-green-600">Envios 1:</strong> <span class="dark:text-zinc-200">09h30 e 13h30.</span></li>
				<li><strong class="text-purple-600">Envios 2:</strong> <span class="dark:text-zinc-200">10h30 e 14h30.</span></li>
				<li><strong class="text-blue-600">Envios 3:</strong> <span class="dark:text-zinc-200">11h30 e 15h30.</span></li>
				<li><strong class="text-orange-600">Envios 4:</strong> <span class="dark:text-zinc-200">12h30 e 16h30.</span></li>
			</ul>
			<h3 class="text-zinc-700 font-semibold dark:text-zinc-500">
				Quantidade de números na LZ Envios: <span class="text-blue-600 font-semibold">{{ quantidades.lzEnvios }}</span>
			</h3>
		</div>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Validadores:</h2>
			<p class="dark:text-zinc-200 mt-4">
				Hoje os validadores são os principais números dentro da operação, quanto mais validadores mais saudável é nossa operação. São os números mais velhos pois são
				adicionados com 40 dias de vida e 300 envios. Porém são também os números que mais enviam. São responsáveis pelos blacklist das campanhas massivas e pela iniciação
				de conversa manual dentro da plataforma.
			</p>
			<ul class="list-disc list-inside mt-4">
				<li>
					<strong class="text-gray-800 dark:text-yellow-600">Validadores com 40 dias ou mais:</strong>
					<span> Pegam uma conversa a cada 50 minutos </span>
					<h3 class="ml-5 text-zinc-700 font-semibold dark:text-zinc-500">
						Quantidade de números validadores com menos de 40 dias:
						<span class="text-blue-600 font-semibold">{{ quantidades.validadoresMenosDeQuarentaDias }}</span>
					</h3>
				</li>
				<li>
					<strong class="text-gray-800 dark:text-yellow-600">Validadores com 30 a 40 dias: (Caso adicionados manualmente como validadores)</strong>
					<span> Pegam uma conversa a cada 140 minutos </span>
					<h3 class="ml-5 text-zinc-700 font-semibold dark:text-zinc-500">
						Quantidade de números validadores com mais de 40 dias:
						<span class="text-blue-600 font-semibold">{{ quantidades.validadoresMaisDeQuarentaDias }}</span>
					</h3>
				</li>
			</ul>
		</div>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Blacklist:</h2>
			<ul class="list-disc list-inside mt-4">
				<li>
					<strong class="text-green-600">Campanhas massivas:</strong>
					<span class="dark:text-zinc-200">
						Hoje os validadores fazem a verificação dos números das campanhas reportando os números que tem WhatsApp vinculado para posteriormente enviar via massivo e
						envia os números blacklist.
					</span>
				</li>
				<li>
					<strong class="text-blue-600">Iniciação manual:</strong>
					<span class="dark:text-zinc-200"> Números do envios1, envios2, envios 3 envios 4 com mais de 20 dias enviados via socket. </span>
				</li>
			</ul>
		</div>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Alternador:</h2>
			<p class="dark:text-zinc-200 mt-4">Robô que passa em todos os computadores a noite abrindo e atualizando WhatsApp e excluindo emuladores banidos.</p>
			<p class="dark:text-zinc-200 mt-2">
				Durante o dia abre a agenda dos números de agenda a cada <strong class="text-blue-600">30 min</strong> deixando o app aberto para salvar todos os números da
				criação.
			</p>
		</div>
		<div class="my-5 p-4 bg-white dark:bg-black shadow-lg rounded-md">
			<h2 class="text-xl text-zinc-700 font-semibold dark:text-white">Alternador Novo (BOT PYTHON):</h2>
			<p class="dark:text-zinc-200 mt-4">Passa em todos os computadores a noite abrindo WhatsApp, digitando código PIN, arrumando tela de backup e reportando banimento.</p>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount} from "vue";
	import TituloPagina from "@/components/tituloPagina.vue";
	import api from "@/services/api";
	import {toast} from "@/hooks/toast";
	import {useRouter} from "vue-router";

	const quantidades = ref({validadoresMaisDeQuarentaDias: 0, validadoresMenosDeQuarentaDias: 0, maturacao: 0, criacaoEScan: 0, lzEnvios: 0});
	const router = useRouter();

	const buscarQuantidades = async () => {
		const response = await api.get("/v1/zap/quantidadesFluxoGeral");

		if (!response.data.success) {
			toast("erro", "error");
		}

		quantidades.value = response.data.quantidades;
	};

	onBeforeMount(async () => await buscarQuantidades());
</script>
